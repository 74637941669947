/* eslint-disable camelcase */
import axios, { AxiosResponse } from 'axios'
import { ActionTree, ActionContext, MutationTree } from 'vuex'
import { make } from 'vuex-pathify'
import { cloneDeep, flow } from 'lodash-es'
import { parseISO, formatRFC3339 } from 'date-fns'
import { NuxtAxiosInstance } from '@nuxtjs/axios'
import {
  CargoTypes, ContainerTypes, TransportModes, Contents, ClientType, Incoterms
} from '../composables/enums'
import { ADMIN, INQUIRY, PUBLIC_INQUIRY } from '~/config/api'
import { RootState } from '~/store'

export interface Entry {
  key?: string,
  quantity: number,
  width: number,
  height: number,
  depth: number,
  weight: number
}

export interface Container {
  key?: string,
  quantity: number,
  container_type: ContainerTypes
}

export interface Location {
  formatted_address: string,
  route: string,
  street_number: string,
  locality: string,
  administrative_area_level_1: string,
  postal_code: string,
  country: string
}

type SpecialContents = Record<Contents, 'yes' | 'no' | ''> | Contents[]
export interface Shipment {
  origin: Location,
  destination: Location,
  transport_date: string,
  transport_modes: TransportModes[],
  cargo_type: CargoTypes,
  description: string,
  incoterm: Incoterms,
  special_contents: SpecialContents,
  cargo_components?: Entry[],
  containers?: Container[]
}

export interface Organization {
  name: string
}

export interface User {
  type?: ClientType,
  organization?: Organization,
  first_name: string,
  last_name: string,
  email: string,
  phone: string,
  agreed_with_tac: boolean,
  receive_promotions: boolean,
  affiliate_code: string
}

export interface ValidationReset {
  incoterm: true,
  details: boolean,
  specifics: boolean,
  contact: boolean,
  cargoType: boolean,
  user: boolean
}

export interface Form {
  currentStep: 1 | 2 | 3 | 4,
  completedSteps: 1 | 2 | 3 | 4,
  validationReset: ValidationReset
  form: {
    origin: Location,
    destination: Location,
    transport_date: string,
    transport_modes: TransportModes[],
    cargo_type: CargoTypes,
    description: string,
    incoterms: Incoterms[],
    special_contents: SpecialContents,
    cargo_components?: Entry[],
    containers?: Container[]
    business?: boolean,
    user?: User,
    user_id?: number
  }
}

export const defaultForm: Form = {
  currentStep: 1,
  completedSteps: 1,
  validationReset: {
    user: true,
    incoterm: true,
    details: true,
    specifics: true,
    contact: true,
    cargoType: true
  },
  form: {
    origin: {
      formatted_address: '',
      route: '',
      street_number: '',
      locality: '',
      administrative_area_level_1: '',
      postal_code: '',
      country: ''
    },
    destination: {
      formatted_address: '',
      route: '',
      street_number: '',
      locality: '',
      administrative_area_level_1: '',
      postal_code: '',
      country: ''
    },
    description: '',
    incoterms: [Incoterms.INCOTERM_EXW],
    cargo_type: CargoTypes.UNSPECIFIED,
    transport_modes: [TransportModes.SEA],
    transport_date: '',
    special_contents: {
      batteries: '',
      textiles: '',
      liquids: ''
    },
    cargo_components: [{
      quantity: 1,
      width: 0,
      height: 0,
      depth: 0,
      weight: 0
    }],
    containers: [{
      quantity: 1,
      container_type: ContainerTypes.UNSPECIFIED
    }]
  }
}

const emptyUser = () => ({
  type: ClientType.BUSINESS,
  organization: {
    name: ''
  },
  first_name: '',
  last_name: '',
  email: '',
  phone: '',
  receive_promotions: false,
  agreed_with_tac: false,
  affiliate_code: ''
})

function deleteKeys<T extends Container | Entry> (coll: T[]): T[] {
  return coll.map((item) => {
    delete item.key
    return item
  })
}

function sanitizeLocation (location: Location): Location {
  return (Object.entries(location) as Array<[keyof Location, string]>)
    .reduce((agg, [key, value]) => {
      agg[key] = value.trim()
      return agg
    }, {} as Location)
}

export function convertDate<T extends { transport_date: string }> (data: T): T {
  const result = cloneDeep(data)
  result.transport_date = formatRFC3339(parseISO(data.transport_date))
  return result
}

// Convert special contents object to array
export function convertSpecialContents<T extends { special_contents: SpecialContents }> (data: T): T {
  const result = cloneDeep(data)
  result.special_contents = (Object.entries(data.special_contents) as Array<[Contents, 'yes' | 'no']>)
    .reduce((result, [item, presence]) => {
      if (presence === 'yes') {
        result.push(item)
      }
      return result
    }, [] as Contents[])
  return result
}

export function sanitizeCargo<T extends {
  cargo_type: CargoTypes, containers?: Container[], cargo_components?: Entry[] }> (data: T): T {
  const result = cloneDeep(data)
  // If containers are selected as cargo type, delete cargo_components and vice versa
  if (result.cargo_type === CargoTypes.CONTAINERS) {
    delete result.cargo_components
    // Delete key property from items
    if (result.containers) {
      result.containers = deleteKeys(result.containers)
    }
  } else {
    delete result.containers
    // Delete key property from items
    if (result.cargo_components) {
      result.cargo_components = deleteKeys(result.cargo_components)
    }
  }
  return result
}

export function sanitizeOrigin<T extends{ origin: Location, incoterm: Incoterms }> (data: T): T {
  const result = cloneDeep(data)
  result.origin = sanitizeLocation(result.origin)
  const fullAddressRequired = [
    Incoterms.INCOTERM_UNSPECIFIED,
    Incoterms.INCOTERM_EXW,
    Incoterms.INCOTERM_DDP,
    Incoterms.INCOTERM_DAP
  ].includes(data.incoterm)
  if (!fullAddressRequired) {
    result.origin.formatted_address = ''
  }
  return result
}

export function sanitizeDestination<T extends { destination: Location }> (data: T): T {
  const result = cloneDeep(data)
  result.destination = sanitizeLocation(result.destination)
  return result
}

export function sanitizeUser<T extends { user: User, business: boolean }> (data: T): T {
  if (!data.user) { return data }
  const result = cloneDeep(data)
  // Remove organization name if user indicated to be private
  if (result.user.type === 'private') {
    delete result.user.organization
    result.business = false
  } else {
    result.business = true
    result.user.organization!.name = result.user.organization!.name.trim()
  }
  delete result.user.type
  // Trim remaining string fields
  result.user.first_name = result.user.first_name.trim()
  result.user.last_name = result.user.last_name.trim()
  result.user.email = result.user.email.trim()
  result.user.phone = result.user.phone.trim()
  return result
}

export function trimDescription<T extends { description: string }> (data: T): T {
  const result = cloneDeep(data)
  result.description = result.description.trim()
  return result
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const state = () => {
  const data = cloneDeep(defaultForm)
  data.form.user = emptyUser()
  data.form.user_id = 0
  return data
}
export type FormState = ReturnType<typeof state>

export const mutations: MutationTree<FormState> = {
  ...make.mutations(state),
  RESET_FORM (state) {
    Object.assign(state, defaultForm)
    state.form.transport_date = new Date().toISOString().slice(0, 10)
    state.form.user = emptyUser()
  }
}

const submitForm = async (
  commit: ActionContext<Form, Form['form']>['commit'],
  dispatch: ActionContext<Form, Form['form']>['dispatch'],
  axios: NuxtAxiosInstance,
  endpoint: string,
  data: Form['form']) => {
  try {
    const response = await axios.post(endpoint, data)
    commit('RESET_FORM')
    return response
  } catch (e: unknown) {
    const message = getErrorMessage(e)
    dispatch('notifications/notify', { message, color: 'error' }, { root: true })
    throw e
  }
}

export const actions: ActionTree<FormState, RootState> = {
  async submitAsAuthenticatedUser ({ state, commit, dispatch }) {
    const data = cloneDeep(state.form)
    delete data.user
    delete data.user_id
    return await submitForm(commit, dispatch, this.$axios, INQUIRY, sanitize(data))
  },
  async submitWithClient ({ state, commit, dispatch }) {
    const data = cloneDeep(state.form)
    delete data.user
    return await submitForm(commit, dispatch, this.$axios, ADMIN.INQUIRY, sanitize(data))
  },
  async submit ({ state, commit, dispatch }) {
    const data = cloneDeep(state.form)
    delete data.user_id
    return await submitForm(commit, dispatch, this.$axios, PUBLIC_INQUIRY, sanitize(data))
  },
  async submitContactInfo ({ state, commit, dispatch }) {
    if (typeof state.form.user === 'undefined') {
      return
    }
    const user: User = { ...state.form.user }
    const data = { user, business: true }

    if (user.type === 'private') {
      delete data.user.organization
      data.business = false
    }
    delete user.type

    try {
      const response = await this.$axios.post(PUBLIC_INQUIRY, data)
      commit('RESET_FORM')
      return response
    } catch (e) {
      const message = getErrorMessage(e)
      dispatch('notifications/notify', { message, color: 'error' }, { root: true })
      throw e
    }
  }
}

export const sanitize: (data: Form['form']) => Form['form'] = flow([
  convertDate, convertSpecialContents, sanitizeCargo, sanitizeOrigin, sanitizeDestination,
  trimDescription, sanitizeUser
])

const getErrorMessage = (e: unknown): string => {
  if (axios.isAxiosError(e) && typeof e.response !== 'undefined') {
    const response: AxiosResponse<any> = e.response
    return response.data?.message || response.data || `${response.status}: ${response.statusText}`
  } else {
    return String(e)
  }
}
