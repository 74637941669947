






import { defineComponent } from '@vue/composition-api'
import smoothReflow from 'vue-smooth-reflow'

export default defineComponent({
  name: 'SmoothReflow',
  mixins: [smoothReflow],
  props: {
    tag: {
      type: String,
      default: 'div'
    },
    options: {
      type: Object,
      default: () => ({})
    }
  },
  mounted () {
    if (typeof this.$smoothReflow !== 'undefined') {
      this.$smoothReflow(this.options)
    }
  }
})
