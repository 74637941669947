import Vue from 'vue'

import { ActionTree, MutationTree } from 'vuex'
import { TranslateResult } from 'vue-i18n'
export interface Message {
  message: TranslateResult,
  color: string,
  value: boolean,
  timeout: number,
  closeText: string,
}

const restingState: Message = {
  message: '',
  color: 'primary',
  value: false,
  timeout: 5000,
  closeText: 'common.close'
}

export const state = () => ({
  pending: [] as Message[],
  current: { ...restingState } as Message
})
export type NotificationState = ReturnType<typeof state>

export const mutations: MutationTree<NotificationState> = {
  reset (state) {
    state.current = { ...restingState }
  },
  shiftFromPending (state) {
    state.pending.splice(0, 1)
  },
  pushToPending (state, message: Message) {
    state.pending.push(message)
  },
  setCurrent (state, message: Message) {
    state.current = message
  }
}

export type NotificationActions = ActionTree<NotificationState, {}>

export const actions: NotificationActions = {
  notify ({ state, commit, dispatch }, message: Partial<Message>) {
    commit('pushToPending', {
      ...restingState,
      value: true,
      ...message
    })
    if (!state.current.value) {
      return dispatch('pop')
    }
    return true
  },
  async pop ({ state, commit }) {
    commit('reset')
    if (state.pending.length === 0) {
      return true
    }
    await Vue.nextTick()
    const message = state.pending[0]
    commit('setCurrent', message)
    commit('shiftFromPending')
    return true
  }
}
