import { Model } from '@vuex-orm/core'
import { Config } from '@vuex-orm/plugin-axios'
import { pick } from 'lodash-es'
import { ADMIN, REVIEW } from '~/config/api'
import User from '~/models/user'
import Organization from '~/models/organization'
import Shipment from '~/models/shipment'
import { UserRole } from '~/composables/enums'

const endpoints: Record<UserRole, string> = {
  [UserRole.ADMIN]: ADMIN.REVIEW,
  [UserRole.CLIENT]: REVIEW,
  [UserRole.FORWARDER]: ''
}

export enum ReviewStatus {
  STATUS_UNSPECIFIED,
  STATUS_PENDING,
  STATUS_POSTPONED,
  STATUS_REFUSED,
  STATUS_SUBMITTED,
  STATUS_RETRACTED
}
export default class Review extends Model {
  // This is the name used as module name of the Vuex Store.
  static entity = 'reviews'

  // List of all fields (schema) of the post model. `this.attr` is used
  // for the generic field type. The argument is the default value.
  static fields () {
    return {
      id: this.number(0),
      created_at: this.attr(''),
      updated_at: this.attr(''),
      deleted_at: this.attr(null),
      shipment_id: this.attr(null),
      shipment: this.belongsTo(Shipment, 'shipment_id'),
      forwarder_id: this.attr(null),
      forwarder: this.belongsTo(Organization, 'forwarder_id'),
      customs_agent_id: this.attr(null),
      customs_agent: this.belongsTo(Organization, 'customs_agent_id'),
      user_id: this.attr(null),
      user: this.belongsTo(User, 'user_id'),
      score_overall: this.attr(null),
      score_customs: this.attr(null),
      score_communication: this.attr(null),
      score_delivery: this.attr(null),
      comments: this.attr(null),
      status: this.number(0),
      last_prompt: this.attr(null),
      user_role: this.attr(0)
    }
  }

  id!: number
  created_at!: string
  updated_at!: string
  deleted_at?: string
  shipment_id!: number
  shipment?: Shipment
  forwarder_id!: number
  forwarder?: Organization
  customs_agent_id!: number
  customs_agent?: Organization
  user_id!: number
  user?: User
  score_overall?: number
  score_customs?: number
  score_communication?: number
  score_delivery?: number
  comments?: string
  status!: ReviewStatus
  last_prompt?: string
  user_role!: number

  /**
   * Create a new review
   * @static
   * @param {Review} payload
   * @param {Config} config
   * @param {UserRole} role
   * @returns {Promise<Response>}
   * @memberof Reviews
   */
  public static make (payload: Review, config?: Config, role = UserRole.CLIENT) {
    return Review.api().post(
      endpoints[role],
      this.sanitize(payload, 'create'),
      config
    )
  }

  /**
   * Update an existing review
   * @static
   * @param {Review} payload
   * @param {Config} config
   * @param {UserRole} role
   * @param {boolean} optimistic
   * @returns {Promise<Response>}
   * @memberof Reviews
   */
  public async persist (payload: Review, config?: Config, role = UserRole.CLIENT, optimistic = true) {
    const originalRecord = this.$toJson()
    try {
      if (optimistic) {
        await this.$update(payload.$toJson())
      }
      return Review.api().put(
        `${endpoints[role]}/${this.id}`,
        Review.sanitize(payload, 'update'),
        config
      )
    } catch (e) {
      if (optimistic) {
        this.$update(originalRecord)
      }
      throw e
    }
  }

  /**
   * Fetch postponed reviews
   *
   * @static
   * @param {Config} config
   * @returns {Promise<Response>}
   * @memberof Reviews
   */
  public static fetchPending (config?: Config, role = UserRole.CLIENT) {
    return this.api().get(`${endpoints[role]}/pending`, config)
  }

  /**
   * Delete the specified review
   * @static
   * @param id The ID of the review to delete
   * @param config The axios or vuex-orm config parameters
   * @returns A response object containing the data
   * @memberof Review
   */
  public static deleteReview (id: number|string, config?: Config) {
    return this.api().delete(`${ADMIN.REVIEW}/${id}`, config)
  }

  /**
   * Retract the specified review
   * @static
   * @param id The ID of the review to delete
   * @param config The axios or vuex-orm config parameters
   * @returns A response object containing the data
   * @memberof Review
   */
  public static retract (id: number|string, config?: Config) {
    return this.api().post(`${ADMIN.REVIEW}/${id}/invalidate`, {}, config)
  }

  public static sanitize (payload: Review, mode: 'create'|'update'): Partial<Review> {
    const pickFields: Array<keyof Review> = [
      'score_overall', 'score_customs', 'score_communication', 'score_delivery', 'comments', 'status'
    ]

    if (mode === 'create') {
      pickFields.push('shipment_id')
    }

    return pick(payload, pickFields)
  }
}
