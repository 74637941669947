





























































































import { computed, defineComponent, reactive } from '@nuxtjs/composition-api'
import {
  mdiApps, mdiDomain, mdiLayersSearch, mdiPackageVariantClosed, mdiLogout, mdiShieldAccount,
  mdiTrainCar, mdiCubeSend, mdiAccount, mdiFileDocument, mdiAirplaneLanding, mdiAirplaneTakeoff,
  mdiTable, mdiCashMultiple
} from '@mdi/js'
import { useVuexPathify } from '~/composables/hooks/store'
import { OrganizationType } from '~/composables/enums'
import { useI18n, usePermissions } from '~/composables/hooks'
import NavLink from '~/components/App/NavLink'
import LanguageSwitcher from '~/components/LanguageSwitcher'
import User from '~/models/user'
import { useAuth } from '~/composables/helpers/auth'
import { NavItem } from '~/composables/types'

export default defineComponent({
  name: 'NavigationDrawer',
  components: { LanguageSwitcher, NavLink },
  setup () {
    const { get } = useVuexPathify()
    const auth = useAuth()
    const { i18n } = useI18n()
    const permissions = usePermissions()
    const loggedInUser = get<User>('loggedInUser')

    const username = computed(() => (loggedInUser.value.first_name || loggedInUser.value.last_name)
      ? `${loggedInUser.value.first_name} ${loggedInUser.value.last_name}`
      : auth.user.name
    )

    const menuOpen = reactive({
      admin: false,
      forwarder: false,
      shipper: false,
      profile: false
    })

    const navItems = computed<NavItem[]>(() => {
      const result = [
        {
          icon: mdiApps,
          title: i18n.t('nav.dashboard'),
          to: { name: 'index' }
        },
        {
          icon: mdiLayersSearch,
          title: i18n.t('nav.inquiries'),
          to: { name: 'inquiries' }
        },
        {
          icon: mdiPackageVariantClosed,
          title: i18n.t('nav.shipments'),
          to: { name: 'shipments' }
        },
        {
          icon: mdiCashMultiple,
          title: i18n.t('nav.invoices'),
          to: { name: 'invoices' }
        }
      ]

      if (loggedInUser.value?.organization?.shipment_overview_columns?.length) {
        result.push({
          icon: mdiTable,
          title: i18n.t('nav.shipmentsOverview'),
          to: { name: 'shipments-overview' }
        })
      }

      return result
    })

    const adminItems = computed(() => {
      const items: NavItem[] = []
      if (permissions.inquiries.read) {
        items.push({
          icon: mdiLayersSearch,
          title: computed(() => i18n.t('nav.inquiries')).value,
          to: { name: 'admin-inquiries' }
        })
      }

      if (permissions.shipments.read) {
        items.push(
          {
            icon: mdiPackageVariantClosed,
            title: i18n.t('nav.shipments'),
            to: { name: 'admin-shipments' }
          },
          {
            icon: mdiAirplaneTakeoff,
            title: i18n.t('nav.departures'),
            to: { name: 'admin-departures' }
          },
          {
            icon: mdiAirplaneLanding,
            title: i18n.t('nav.arrivals'),
            to: { name: 'admin-arrivals' }
          })
      }

      if (permissions.invoices.read) {
        items.push({
          icon: mdiCashMultiple,
          title: i18n.t('nav.invoices'),
          to: { name: 'admin-invoices' }
        })
      }

      if (permissions.organizations.read) {
        items.push({
          icon: mdiDomain,
          title: i18n.t('nav.organizations'),
          to: { name: 'admin-organizations' }
        })
      }
      return items
    })

    const forwarderItems = computed(() => {
      const items: NavItem[] = []
      if (permissions.shipmentsForwarder.read) {
        items.push({
          icon: mdiPackageVariantClosed,
          title: i18n.t('nav.shipments'),
          to: { name: 'forwarder-shipments' }
        })
      }

      if (permissions.documentsForwarder.read) {
        items.push({
          icon: mdiFileDocument,
          title: i18n.t('nav.documents'),
          to: { name: 'forwarder-documents' }
        })
      }
      return items
    })

    const showForwarderMenu = computed(() =>
      forwarderItems.value.length && loggedInUser.value?.organization?.type === OrganizationType.FORWARDER)

    return {
      adminItems,
      forwarderItems,
      showForwarderMenu,
      navItems,
      menuOpen,
      username,
      mdiLogout,
      mdiShieldAccount,
      mdiTrainCar,
      mdiCubeSend,
      mdiAccount,
      mdiTable,
      mdiCashMultiple,
      permissions
    }
  }
})
