/* eslint-disable camelcase */
import { Model } from '@vuex-orm/core'
import Organization from './organization'
import { Incoterms } from '~/composables/enums'

export enum ShipmentStages {
  SHIPMENT_STAGE_UNSPECIFIED,
  SHIPMENT_STAGE_PRE_CARRIAGE_LOADING,
  SHIPMENT_STAGE_PRE_CARRIAGE_TRANSIT,
  SHIPMENT_STAGE_PRE_CARRIAGE_UNLOADING,
  SHIPMENT_STAGE_EXPORT_FORMALITIES,
  SHIPMENT_STAGE_CARRIAGE_LOADING,
  SHIPMENT_STAGE_CARRIAGE_TRANSIT,
  SHIPMENT_STAGE_CARRIAGE_DISCHARGE,
  SHIPMENT_STAGE_IMPORT_FORMALITIES,
  SHIPMENT_STAGE_ON_CARRIAGE_LOADING,
  SHIPMENT_STAGE_ON_CARRIAGE_TRANSIT,
  SHIPMENT_STAGE_ON_CARRIAGE_UNLOADING
}

export enum ShipmentServices {
  SHIPMENT_SERVICE_UNSPECIFIED,
  SHIPMENT_SERVICE_INSURANCE,
}

export class ShipmentStageModel extends Model {
  static entity = 'shipmentStage'

  static primaryKey = ['forwarder_id', 'shipment_id']

  static fields () {
    return {
      forwarder_id: this.attr(null),
      shipment_id: this.attr(null)
    }
  }
}

export default interface ShipmentStage {
  stage: ShipmentStages,
  shipment_id?: number,
  quote_id?: number,
  forwarder_id: number,
  forwarder?: Organization|null,

  id?: number
  created_at?: string
  updated_at?: string
  deleted_at?: string
}

export function newShipmentStage (data?:Partial<ShipmentStage>): ShipmentStage {
  return {
    stage: data?.stage ?? ShipmentStages.SHIPMENT_STAGE_UNSPECIFIED,
    forwarder_id: data?.forwarder_id ?? 0,
    forwarder: data?.forwarder ?? null
  }
}

export function requiredStages (i: Incoterms) {
  switch (i) {
    case Incoterms.INCOTERM_FCA:
      return [
        ShipmentStages.SHIPMENT_STAGE_UNSPECIFIED,
        ShipmentStages.SHIPMENT_STAGE_PRE_CARRIAGE_TRANSIT,
        ShipmentStages.SHIPMENT_STAGE_PRE_CARRIAGE_UNLOADING,
        ShipmentStages.SHIPMENT_STAGE_CARRIAGE_LOADING,
        ShipmentStages.SHIPMENT_STAGE_CARRIAGE_TRANSIT,
        ShipmentStages.SHIPMENT_STAGE_CARRIAGE_DISCHARGE,
        ShipmentStages.SHIPMENT_STAGE_IMPORT_FORMALITIES,
        ShipmentStages.SHIPMENT_STAGE_ON_CARRIAGE_LOADING,
        ShipmentStages.SHIPMENT_STAGE_ON_CARRIAGE_TRANSIT,
        ShipmentStages.SHIPMENT_STAGE_ON_CARRIAGE_UNLOADING
      ]
    case Incoterms.INCOTERM_FAS:
      return [
        ShipmentStages.SHIPMENT_STAGE_UNSPECIFIED,
        ShipmentStages.SHIPMENT_STAGE_CARRIAGE_LOADING,
        ShipmentStages.SHIPMENT_STAGE_CARRIAGE_TRANSIT,
        ShipmentStages.SHIPMENT_STAGE_CARRIAGE_DISCHARGE,
        ShipmentStages.SHIPMENT_STAGE_IMPORT_FORMALITIES,
        ShipmentStages.SHIPMENT_STAGE_ON_CARRIAGE_LOADING,
        ShipmentStages.SHIPMENT_STAGE_ON_CARRIAGE_TRANSIT,
        ShipmentStages.SHIPMENT_STAGE_ON_CARRIAGE_UNLOADING
      ]
    case Incoterms.INCOTERM_FOB:
      return [
        ShipmentStages.SHIPMENT_STAGE_UNSPECIFIED,
        ShipmentStages.SHIPMENT_STAGE_CARRIAGE_TRANSIT,
        ShipmentStages.SHIPMENT_STAGE_CARRIAGE_DISCHARGE,
        ShipmentStages.SHIPMENT_STAGE_IMPORT_FORMALITIES,
        ShipmentStages.SHIPMENT_STAGE_ON_CARRIAGE_LOADING,
        ShipmentStages.SHIPMENT_STAGE_ON_CARRIAGE_TRANSIT,
        ShipmentStages.SHIPMENT_STAGE_ON_CARRIAGE_UNLOADING
      ]
    case Incoterms.INCOTERM_CFR:
    case Incoterms.INCOTERM_CIF:
      return [
        ShipmentStages.SHIPMENT_STAGE_UNSPECIFIED,
        ShipmentStages.SHIPMENT_STAGE_CARRIAGE_TRANSIT,
        ShipmentStages.SHIPMENT_STAGE_CARRIAGE_DISCHARGE,
        ShipmentStages.SHIPMENT_STAGE_IMPORT_FORMALITIES,
        ShipmentStages.SHIPMENT_STAGE_ON_CARRIAGE_LOADING,
        ShipmentStages.SHIPMENT_STAGE_ON_CARRIAGE_TRANSIT,
        ShipmentStages.SHIPMENT_STAGE_ON_CARRIAGE_UNLOADING
      ]
    default:
      // DPU, DAP, DDP, EXW, CPT, CIP
      return [
        ShipmentStages.SHIPMENT_STAGE_UNSPECIFIED,
        ShipmentStages.SHIPMENT_STAGE_PRE_CARRIAGE_LOADING,
        ShipmentStages.SHIPMENT_STAGE_PRE_CARRIAGE_TRANSIT,
        ShipmentStages.SHIPMENT_STAGE_PRE_CARRIAGE_UNLOADING,
        ShipmentStages.SHIPMENT_STAGE_EXPORT_FORMALITIES,
        ShipmentStages.SHIPMENT_STAGE_CARRIAGE_LOADING,
        ShipmentStages.SHIPMENT_STAGE_CARRIAGE_TRANSIT,
        ShipmentStages.SHIPMENT_STAGE_CARRIAGE_DISCHARGE,
        ShipmentStages.SHIPMENT_STAGE_IMPORT_FORMALITIES,
        ShipmentStages.SHIPMENT_STAGE_ON_CARRIAGE_LOADING,
        ShipmentStages.SHIPMENT_STAGE_ON_CARRIAGE_TRANSIT,
        ShipmentStages.SHIPMENT_STAGE_ON_CARRIAGE_UNLOADING
      ]
  }
}
