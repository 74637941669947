


















































































/* eslint-disable no-undef */
import { defineComponent, ref, computed, onMounted, watch, provide } from '@nuxtjs/composition-api'
import { mdiAlert } from '@mdi/js'
import Cookie from 'js-cookie'
import { Md5 } from 'ts-md5'
import NotificationBox from '~/components/NotificationBox'
import { useVuexPathify } from '~/composables/hooks/store'
import { LoadingUserState, UserRole } from '~/composables/enums'
import { identifyUserForHubspot, setHubspotChatConfig, loadHubspotChatBubble, envIsProduction } from '~/composables/util'
import { processErrors } from '~/composables/errors'
import { provideCountries, useGtm, useI18n, useNotify } from '~/composables/hooks'
import ProfileModalContainer from '~/components/Containers/ProfileModalContainer'
import ReviewNotificationsDialog from '~/components/Reviews/ReviewNotificationsDialog'
import NavigationDrawer from '~/components/App/NavigationDrawer'
import AppBar from '~/components/App/AppBar'
import { providePermissions, useAuth, userCan } from '~/composables/helpers/auth'
import User from '~/models/user'

import Review from '~/models/review'
import { provideWebsocket } from '~/composables/websockets'

export default defineComponent({
  name: 'DefaultLayout',
  components: {
    NavigationDrawer,
    AppBar,
    NotificationBox,
    ProfileModalContainer,
    ReviewNotificationsDialog
  },
  setup () {
    providePermissions()
    provideWebsocket()
    provideCountries()
    provide('userRole', UserRole.CLIENT)
    const { get, sync } = useVuexPathify()
    const { i18n } = useI18n()
    const auth = useAuth()
    const gtm = useGtm(false)
    const notify = useNotify()

    const userLoaded = ref<LoadingUserState>(LoadingUserState.LOADING)
    const userLocation = sync<{lat: number, lng: number}>('userLocation')
    const loggedInUser = get<User>('loggedInUser')
    const token = ref('')
    const reviewDialog = ref(false)
    const alertVisible = ref(false)
    const ctaText = computed(() => i18n.t('app.callToAction'))
    const ctaHash = computed(() => Md5.hashStr(ctaText.value.toString()))

    const loadingStatus = computed(() => {
      return {
        [LoadingUserState.LOADING]: 'loading',
        [LoadingUserState.FAILED]: 'failed',
        [LoadingUserState.SUCCESS]: 'success'
      }[userLoaded.value]
    })

    const loadingReviews = ref(false)
    const reviews = ref<Review[]>()
    const role = computed(() =>
      // On the default page, we cannot inject userRole since admin role is provided only when admin goes to admin page,
      // so here we check auth0 permission to see if the logged in user is admin or not.
      // This will be also used in ReviewNotificationsDialog
      userCan('read:reviews') && userCan('write:reviews') ? UserRole.ADMIN : UserRole.CLIENT
    )
    const fetchPendingReviews = async () => {
      loadingReviews.value = true
      try {
        const res = await Review.fetchPending({}, role.value)
        reviews.value = res.response.data.data
        if (reviews.value && reviews.value.length) {
          reviewDialog.value = true
        }
      } catch (e) {
        processErrors(e, notify, false)
      } finally {
        loadingReviews.value = false
      }
    }

    onMounted(() => {
      const location = auth.user['https://cargoplot.com/geo']
      if (typeof location !== 'undefined') {
        userLocation.value = {
          lat: location.latitude,
          lng: location.longitude
        }
      } else {
        userLocation.value = {
          lat: 0,
          lng: 0
        }
      }
      fetchPendingReviews()
    })

    const showCTAIfApplicable = () => {
      if (!ctaText.value) {
        return
      }
      const storedHash = Cookie.get(`closed-alert:${loggedInUser.value.id}`)
      if (storedHash !== ctaHash.value) {
        alertVisible.value = true
      }
    }

    watch(alertVisible, (value, prevValue) => {
      if (value || !loggedInUser.value) { return }
      if (prevValue) {
        Cookie.set(`closed-alert:${loggedInUser.value.id}`, ctaHash.value, { expires: 7 })
      }
    })

    watch(userLoaded, async (status) => {
      if (status !== LoadingUserState.SUCCESS) { return }
      try {
        token.value = await User.getHubspotToken()
        setHubspotChatConfig(auth.user.email, token.value)
      } catch (e) { // Don't bother user with this error
      }
      loadHubspotChatBubble()
      identifyUserForHubspot(auth.user.email)
      gtm.push({
        event: 'login',
        user_id: loggedInUser.value.id.toString(),
        method: auth.user.sub.split('|')[0]
      })
      showCTAIfApplicable()
    })

    return {
      drawer: ref(false),
      clipped: sync<boolean>('clipped'),
      userLoaded,
      loadingStatus,
      reviews,
      reviewDialog,
      role,
      envIsProduction,
      loadingReviews,
      alertVisible,
      mdiAlert,
      ctaText,
      ctaHash
    }
  },
  head () {
    return {
      ...this.$nuxtI18nHead({ addSeoAttributes: true }),
      script: [
        {
          id: 'hubspot-init',
          innerHTML: 'window.hsConversationsSettings = { loadImmediately: false }',
          type: 'text/javascript'
        },
        {
          src: '/js/hubspot.js'
        },
        {
          src: `//js.hs-scripts.com/${this.$config.hubspotAppKey}.js`,
          defer: true,
          body: true,
          type: 'text/javascript',
          id: 'hs-script-loader'
        },
        {
          src: `https://maps.googleapis.com/maps/api/js?key=${this.$config.googleCloudAPIKey}&libraries=places`,
          defer: true
        }
      ],
      __dangerouslyDisableSanitizersByTagID: {
        'hubspot-init': ['innerHTML']
      }
    }
  }
})
