







































import { defineComponent, onMounted, watch, ref, PropType } from '@nuxtjs/composition-api'
import { Item } from '@vuex-orm/core'
import { useNotify, useI18n } from '~/composables/hooks'
import { useVuexPathify } from '~/composables/hooks/store'
import { LoadingUserState, OrganizationType } from '~/composables/enums'
import { useSync } from '~/composables/hooks/propsync'
import { processErrors } from '~/composables/errors'
import User from '~/models/user'
import ProfileModal from '~/components/App/ProfileModal'
import { useAuth } from '~/composables/helpers/auth'
import ShipmentsPerYearSelector from '~/components/Forms/ShipmentsPerYearSelector'
import SaveButton from '~/components/Forms/SaveButton'

export default defineComponent({
  name: 'ProfileModalContainer',
  components: { ProfileModal, ShipmentsPerYearSelector, SaveButton },
  props: {
    userLoaded: {
      type: Number as PropType<LoadingUserState>,
      required: true
    }
  },
  setup (props, { emit }) {
    const { i18n } = useI18n()
    const { sync } = useVuexPathify()
    const propSync = useSync(props, emit)
    const notify = useNotify()
    const auth = useAuth()

    const localUserLoaded = propSync('userLoaded')

    // Holds server side validation errors to be propagated to the form
    const ssValidationErrors = ref({})
    // Flag indicating if server communication is in progress
    const saving = ref(false)

    // Fetch the current user from our own API (not from Auth0)
    const loggedInUser = sync<Item<User>>('loggedInUser')
    const shpPerYearBuffer = ref(loggedInUser.value?.organization?.shipments_per_year)

    const fetchCurrentUser = async () => {
      try {
        await User.getProfile()
        setLoggedInUser()
        localUserLoaded.value = LoadingUserState.SUCCESS
      } catch (e) {
        processErrors(e, notify)
        localUserLoaded.value = LoadingUserState.FAILED
      }
    }
    // Update vuex store's loggedInUser with data of current user's (vuex-orm) entity
    const setLoggedInUser = () => {
      loggedInUser.value = User.query()
        .where('email', auth.user.email)
        .with('organization')
        .first()
    }
    // Fetch user when component is mounted
    onMounted(fetchCurrentUser)

    // If logged in user's profile is incomplete, show modal with request to
    // provide missing information
    const showProfileModal = ref(false)
    const showShipmentFrequencyDialog = ref(false)

    const checkProfileInfo = (user: User) => {
      if ([user.first_name, user.last_name, user.email].includes('')) {
        showProfileModal.value = true
      } else if (user.organization && !user.organization.shipments_per_year && user.organization.type === OrganizationType.CLIENT) {
        showShipmentFrequencyDialog.value = true
      }
    }
    watch(
      loggedInUser, (user) => {
        if (user?.email) {
          shpPerYearBuffer.value = user.organization?.shipments_per_year
          checkProfileInfo(user)
        }
      },
      { immediate: true }
    )

    const updateProfile = async (user: User) => {
      try {
        saving.value = true
        await User.updateProfile(user)
        showProfileModal.value = false
        notify({ message: i18n.t('notifications.changesSaved'), color: 'success' })
        setLoggedInUser()
      } catch (e) {
        ssValidationErrors.value = processErrors(e, notify)
      } finally {
        saving.value = false
      }
    }

    const updateShipmentsPerYear = async () => {
      if (!loggedInUser.value?.organization) {
        return
      }
      try {
        saving.value = true
        await loggedInUser.value.organization.persist({ shipments_per_year: shpPerYearBuffer.value })
        showShipmentFrequencyDialog.value = false
        notify({ message: i18n.t('notifications.changesSaved'), color: 'success' })
        setLoggedInUser()
      } catch (e) {
        processErrors(e, notify)
      } finally {
        saving.value = false
      }
    }

    return {
      showProfileModal,
      showShipmentFrequencyDialog,
      loggedInUser,
      ssValidationErrors,
      saving,
      shpPerYearBuffer,
      updateProfile,
      updateShipmentsPerYear
    }
  }
})
