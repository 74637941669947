import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _5f6a6a55 = () => interopDefault(import('../pages/admin.vue' /* webpackChunkName: "pages/admin" */))
const _4edbcfc4 = () => interopDefault(import('../pages/admin/arrivals.vue' /* webpackChunkName: "pages/admin/arrivals" */))
const _4dd999ae = () => interopDefault(import('../pages/admin/departures.vue' /* webpackChunkName: "pages/admin/departures" */))
const _37f71aae = () => interopDefault(import('../pages/admin/inquiries/index.vue' /* webpackChunkName: "pages/admin/inquiries/index" */))
const _c9fc5de0 = () => interopDefault(import('../pages/admin/invoices.vue' /* webpackChunkName: "pages/admin/invoices" */))
const _b1f1ee34 = () => interopDefault(import('../pages/admin/organizations.vue' /* webpackChunkName: "pages/admin/organizations" */))
const _5b726a2e = () => interopDefault(import('../pages/admin/organizations/index.vue' /* webpackChunkName: "pages/admin/organizations/index" */))
const _48a718de = () => interopDefault(import('../pages/admin/organizations/_id.vue' /* webpackChunkName: "pages/admin/organizations/_id" */))
const _79a4d1bc = () => interopDefault(import('../pages/admin/shipments/index.vue' /* webpackChunkName: "pages/admin/shipments/index" */))
const _22bef460 = () => interopDefault(import('../pages/admin/inquiries/create.vue' /* webpackChunkName: "pages/admin/inquiries/create" */))
const _7a47f096 = () => interopDefault(import('../pages/admin/inquiries/_id.vue' /* webpackChunkName: "pages/admin/inquiries/_id" */))
const _a3ca0bec = () => interopDefault(import('../pages/admin/shipments/_id.vue' /* webpackChunkName: "pages/admin/shipments/_id" */))
const _17b2ce90 = () => interopDefault(import('../pages/forwarder.vue' /* webpackChunkName: "pages/forwarder" */))
const _23439538 = () => interopDefault(import('../pages/forwarder/documents/index.vue' /* webpackChunkName: "pages/forwarder/documents/index" */))
const _177e9f85 = () => interopDefault(import('../pages/forwarder/shipments/index.vue' /* webpackChunkName: "pages/forwarder/shipments/index" */))
const _360240cc = () => interopDefault(import('../pages/forwarder/documents/_id.vue' /* webpackChunkName: "pages/forwarder/documents/_id" */))
const _76c8bf2d = () => interopDefault(import('../pages/forwarder/shipments/_id.vue' /* webpackChunkName: "pages/forwarder/shipments/_id" */))
const _3591acaa = () => interopDefault(import('../pages/inquiries.vue' /* webpackChunkName: "pages/inquiries" */))
const _290a83ee = () => interopDefault(import('../pages/inquiries/index.vue' /* webpackChunkName: "pages/inquiries/index" */))
const _1ffa4490 = () => interopDefault(import('../pages/inquiries/create.vue' /* webpackChunkName: "pages/inquiries/create" */))
const _4fa4a9d6 = () => interopDefault(import('../pages/inquiries/_id.vue' /* webpackChunkName: "pages/inquiries/_id" */))
const _e3336060 = () => interopDefault(import('../pages/invoices.vue' /* webpackChunkName: "pages/invoices" */))
const _2dfb1658 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _4bb7511c = () => interopDefault(import('../pages/profile/index.vue' /* webpackChunkName: "pages/profile/index" */))
const _977dff3c = () => interopDefault(import('../pages/shipments/index.vue' /* webpackChunkName: "pages/shipments/index" */))
const _4c7a11ac = () => interopDefault(import('../pages/auth/authorize.vue' /* webpackChunkName: "pages/auth/authorize" */))
const _8e1272a8 = () => interopDefault(import('../pages/auth/login.vue' /* webpackChunkName: "pages/auth/login" */))
const _24a40ace = () => interopDefault(import('../pages/shipments/overview.vue' /* webpackChunkName: "pages/shipments/overview" */))
const _f910996c = () => interopDefault(import('../pages/shipments/_id.vue' /* webpackChunkName: "pages/shipments/_id" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/admin",
    component: _5f6a6a55,
    name: "admin___en",
    children: [{
      path: "arrivals",
      component: _4edbcfc4,
      name: "admin-arrivals___en"
    }, {
      path: "departures",
      component: _4dd999ae,
      name: "admin-departures___en"
    }, {
      path: "inquiries",
      component: _37f71aae,
      name: "admin-inquiries___en"
    }, {
      path: "invoices",
      component: _c9fc5de0,
      name: "admin-invoices___en"
    }, {
      path: "organizations",
      component: _b1f1ee34,
      children: [{
        path: "",
        component: _5b726a2e,
        name: "admin-organizations___en"
      }, {
        path: ":id",
        component: _48a718de,
        name: "admin-organizations-id___en"
      }]
    }, {
      path: "shipments",
      component: _79a4d1bc,
      name: "admin-shipments___en"
    }, {
      path: "inquiries/create",
      component: _22bef460,
      name: "admin-inquiries-create___en"
    }, {
      path: "inquiries/:id",
      component: _7a47f096,
      name: "admin-inquiries-id___en"
    }, {
      path: "shipments/:id",
      component: _a3ca0bec,
      name: "admin-shipments-id___en"
    }]
  }, {
    path: "/forwarder",
    component: _17b2ce90,
    name: "forwarder___en",
    children: [{
      path: "documents",
      component: _23439538,
      name: "forwarder-documents___en"
    }, {
      path: "shipments",
      component: _177e9f85,
      name: "forwarder-shipments___en"
    }, {
      path: "documents/:id",
      component: _360240cc,
      name: "forwarder-documents-id___en"
    }, {
      path: "shipments/:id",
      component: _76c8bf2d,
      name: "forwarder-shipments-id___en"
    }]
  }, {
    path: "/inquiries",
    component: _3591acaa,
    children: [{
      path: "",
      component: _290a83ee,
      name: "inquiries___en"
    }, {
      path: "create",
      component: _1ffa4490,
      name: "inquiries-create___en"
    }, {
      path: ":id",
      component: _4fa4a9d6,
      name: "inquiries-id___en"
    }]
  }, {
    path: "/invoices",
    component: _e3336060,
    name: "invoices___en"
  }, {
    path: "/nl",
    component: _2dfb1658,
    name: "index___nl"
  }, {
    path: "/profile",
    component: _4bb7511c,
    name: "profile___en"
  }, {
    path: "/shipments",
    component: _977dff3c,
    name: "shipments___en"
  }, {
    path: "/auth/authorize",
    component: _4c7a11ac,
    name: "auth-authorize___en"
  }, {
    path: "/auth/login",
    component: _8e1272a8,
    name: "auth-login___en"
  }, {
    path: "/nl/admin",
    component: _5f6a6a55,
    name: "admin___nl",
    children: [{
      path: "arrivals",
      component: _4edbcfc4,
      name: "admin-arrivals___nl"
    }, {
      path: "departures",
      component: _4dd999ae,
      name: "admin-departures___nl"
    }, {
      path: "inquiries",
      component: _37f71aae,
      name: "admin-inquiries___nl"
    }, {
      path: "invoices",
      component: _c9fc5de0,
      name: "admin-invoices___nl"
    }, {
      path: "organizations",
      component: _b1f1ee34,
      children: [{
        path: "",
        component: _5b726a2e,
        name: "admin-organizations___nl"
      }, {
        path: ":id",
        component: _48a718de,
        name: "admin-organizations-id___nl"
      }]
    }, {
      path: "shipments",
      component: _79a4d1bc,
      name: "admin-shipments___nl"
    }, {
      path: "inquiries/create",
      component: _22bef460,
      name: "admin-inquiries-create___nl"
    }, {
      path: "inquiries/:id",
      component: _7a47f096,
      name: "admin-inquiries-id___nl"
    }, {
      path: "shipments/:id",
      component: _a3ca0bec,
      name: "admin-shipments-id___nl"
    }]
  }, {
    path: "/nl/forwarder",
    component: _17b2ce90,
    name: "forwarder___nl",
    children: [{
      path: "documents",
      component: _23439538,
      name: "forwarder-documents___nl"
    }, {
      path: "shipments",
      component: _177e9f85,
      name: "forwarder-shipments___nl"
    }, {
      path: "documents/:id",
      component: _360240cc,
      name: "forwarder-documents-id___nl"
    }, {
      path: "shipments/:id",
      component: _76c8bf2d,
      name: "forwarder-shipments-id___nl"
    }]
  }, {
    path: "/nl/inquiries",
    component: _3591acaa,
    children: [{
      path: "",
      component: _290a83ee,
      name: "inquiries___nl"
    }, {
      path: "create",
      component: _1ffa4490,
      name: "inquiries-create___nl"
    }, {
      path: ":id",
      component: _4fa4a9d6,
      name: "inquiries-id___nl"
    }]
  }, {
    path: "/nl/invoices",
    component: _e3336060,
    name: "invoices___nl"
  }, {
    path: "/nl/profile",
    component: _4bb7511c,
    name: "profile___nl"
  }, {
    path: "/nl/shipments",
    component: _977dff3c,
    name: "shipments___nl"
  }, {
    path: "/shipments/overview",
    component: _24a40ace,
    name: "shipments-overview___en"
  }, {
    path: "/nl/auth/authorize",
    component: _4c7a11ac,
    name: "auth-authorize___nl"
  }, {
    path: "/nl/auth/login",
    component: _8e1272a8,
    name: "auth-login___nl"
  }, {
    path: "/nl/shipments/overview",
    component: _24a40ace,
    name: "shipments-overview___nl"
  }, {
    path: "/nl/shipments/:id",
    component: _f910996c,
    name: "shipments-id___nl"
  }, {
    path: "/shipments/:id",
    component: _f910996c,
    name: "shipments-id___en"
  }, {
    path: "/",
    component: _2dfb1658,
    name: "index___en"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
