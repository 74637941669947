/* eslint-disable camelcase */
import { Model } from '@vuex-orm/core'
import { Currency } from '~/composables/enums'
import { ADMIN } from '~/config/api'

export default class ExchangeRate extends Model {
  // This is the name used as module name of the Vuex Store.
  static entity = 'exhangeRates'

  static primaryKey = 'currency'

  static fields () {
    return {
      currency: this.string(''),
      rate: this.number(0)
    }
  }

  currency!: string
  rate!: number

  static fetch () {
    return this.api().get(ADMIN.EXCHANGERATE, {
      dataTransformer: ({ data }) => {
        return data.data.exchange_rates
      }
    })
  }

  static findLatestRate (currency: Currency) {
    return this.query().find(currency)?.rate ?? 0
  }
}
