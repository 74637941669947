/* eslint-disable camelcase */
import { Model } from '@vuex-orm/core'
import { Config } from '@vuex-orm/plugin-axios'
import { cloneDeep, omit, pick, orderBy, isNil } from 'lodash-es'
import CargoComponent from './cargoComponent'
import LineItem from './lineItem'
import Organization, { AggregateScore } from './organization'
import Container from './container'
import Inquiry from './inquiry'
import Shipment from './shipment'
import Location from './location'
import ShipmentStage, { ShipmentStages } from './shipmentStage'
import { CarrierRoutes } from './carriers'
import { VAT } from './vat'
import { Modify } from '~/composables/types'
import { CargoTypes, ContainerTypes, Currency, Incoterms, QuoteStatus, TransportModes, UserRole } from '~/composables/enums'
import { QUOTE, INQUIRY, ADMIN } from '~/config/api'

export enum QuoteSource {
  User,
  QuoteEngine
}

const dataTransformer: Config['dataTransformer'] = ({ data }) => {
  return data.data.map((quote: Quote) => {
    quote.source = QuoteSource.QuoteEngine
    const sortFields: (keyof LineItem)[] = ['shipment_stage', 'rank']
    quote.line_items = orderBy(quote.line_items, sortFields, ['asc', 'asc'])
    return quote
  })
}

export default class Quote extends Model {
  // This is the name used as module name of the Vuex Store.
  static entity = 'quotes'

  // List of all fields (schema) of the post model. `this.attr` is used
  // for the generic field type. The argument is the default value.
  static fields () {
    return {
      id: this.attr(0),
      created_at: this.attr(''),
      updated_at: this.attr(''),
      deleted_at: this.attr(null),
      published_at: this.attr(null),
      accepted_at: this.attr(null),
      inquiry_id: this.attr(null),
      inquiry: this.belongsTo(Inquiry, 'inquiry_id'),
      shipment_id: this.attr(null),
      shipment: this.belongsTo(Shipment, 'shipment_id'),
      origin: this.attr({}),
      destination: this.attr({}),
      reference: this.attr(''),
      status: this.number(0),
      description: this.string(''),
      expiration_date: this.attr(null),
      valid_from: this.attr(null),
      incoterm: this.number(0),
      total_weight: this.number(0),
      total_volume: this.number(0),
      cargo_type: this.attr(''),
      transport_mode: this.attr(''),
      cargo_components: this.attr([]),
      containers: this.attr([]),
      special_contents: this.attr([]),
      line_items: this.attr([]),
      total_price_euro_cents_ex: this.number(0),
      total_price_euro_cents_inc: this.number(0),
      notes: this.attr(''),
      agent_fee_euro_cents: this.number(0),
      port_of_loading: this.attr(''),
      port_of_discharge: this.attr(''),
      shipment_stages: this.attr([]),
      source: this.number(QuoteSource.User),
      total_vat: this.attr([]),
      carrier_routes: this.attr([]),
      carriers: this.attr([]),
      tags: this.attr([]),
      tail_lift: this.boolean(false),
      pallet_exchange: this.boolean(false),
      insured_goods_value_cents: this.number(0),
      insured_goods_currency: this.attr(null),
      free_demurrage_days: this.number(0),
      free_detention_days: this.number(0),
      min_transit_days: this.number(0)
    }
  }

  id!: number|string
  created_at!: string
  updated_at!: string
  deleted_at?: string
  inquiry_id?: Inquiry['id']
  inquiry?: Inquiry
  shipment_id?: Shipment['id']
  shipment?: Shipment
  origin!: Location
  destination!: Location
  reference!: string
  status!: QuoteStatus
  description!: string
  expiration_date?: string
  valid_from?: string
  incoterm!: Incoterms
  total_weight!: number
  total_volume!: number
  cargo_type!: CargoTypes
  published_at?: string
  accepted_at?: string
  transport_mode?: TransportModes
  cargo_components!: CargoComponent[]
  containers!: Container[]
  special_contents!: string[]
  line_items!: LineItem[]
  total_price_euro_cents_ex!: number
  total_price_euro_cents_inc!: number
  notes?: string
  agent_fee_euro_cents!: number
  port_of_loading?: string
  port_of_discharge?: string
  shipment_stages!: ShipmentStage[]|null
  source!: QuoteSource
  total_vat?: VAT[]
  carrier_routes?: CarrierRoutes[]
  carriers?: string[]
  tags!: string[]
  tail_lift!: boolean
  pallet_exchange!: boolean
  insured_goods_value_cents!: number
  insured_goods_currency?: Currency
  free_demurrage_days!: number
  free_detention_days!: number
  min_transit_days!: number

  /**
   * Fetch generated quotes for a stored inquiry
   */
  public static async fetchGenerated (inquiryId: Inquiry['id'], config?: Config, role = UserRole.CLIENT) {
    // First delete all present generated quotes currently available for this inquiry
    await this.deleteGenerated(inquiryId)
    const endpoint = role === UserRole.ADMIN ? ADMIN.INQUIRY : INQUIRY

    return this.api().get(`${endpoint}/${inquiryId}/prices`, {
      ...config,
      dataTransformer
    })
  }

  /**
   * Update the current quote instance (cargoplot employees)
   */
  public async persist (data: Quote, config?: Config, optimistic = true) {
    const originalRecord = this.$toJson()
    try {
      if (optimistic) {
        await this.$update(data)
      }
      const payload = data.inquiry_id
        ? Quote.sanitizeQuote(data)
        : Quote.sanitizePriceUpdate(data)
      const endPoint = data.inquiry_id
        ? ADMIN.QUOTE
        : ADMIN.PRICEUPDATE
      return Quote.api().put(`${endPoint}/${this.id}`, payload, config)
    } catch (e) {
      if (optimistic) {
        this.$update(originalRecord)
      }
      throw e
    }
  }

  /**
   * Accept a quote
   */
  public accept (config?: Config, role = UserRole.CLIENT) {
    if (this.source === QuoteSource.QuoteEngine) {
      if (role === UserRole.ADMIN) {
        return Quote.api().post(`${ADMIN.INQUIRY}/${this.inquiry_id}/accept/${this.id}`, {}, config)
      } else {
        return Quote.api().post(`${INQUIRY}/${this.inquiry_id}/accept/${this.id}`, {}, config)
      }
    } else if (role === UserRole.ADMIN) {
      this.status = QuoteStatus.STATUS_ACCEPTED
      const payload = Quote.sanitizeQuote(this)
      return Quote.api().put(`${ADMIN.QUOTE}/${this.id}`, payload, config)
    } else {
      return Quote.api().post(`${QUOTE}/${this.id}/accept`, {}, config)
    }
  }

  /**
   * Save a new Quotes (cargoplot employees)
   */
  public static createNew (data: Quote, config?: Config) {
    const payload = data.inquiry_id
      ? this.sanitizeQuote(data)
      : this.sanitizePriceUpdate(data)
    const endPoint = data.inquiry_id ? ADMIN.QUOTE : ADMIN.PRICEUPDATE
    return this.api().post(endPoint, payload, config)
  }

  /**
   * Delete the current quote instance (cargoplot employees)
   */
  public static deleteOne (id: number|string) {
    return this.api().delete(`${ADMIN.QUOTE}/${id}`, {
      delete: id
    })
  }

  public static async searchLineItemDescriptions (query: string|null): Promise<string[]|null> {
    if (!query || query.length < 3) {
      return []
    }
    const resp = await this.api().get(`${ADMIN.QUOTE}/lineitems`, {
      params: {
        limit: 10,
        searchterm: query
      },
      save: false
    })
    return resp.response.data.data
  }

  /**
   * Remove data from quote that should not be sent to the API
   */
  public static sanitizeQuote (quoteData: Quote) {
    interface QuotePayload extends Modify<Partial<Quote>, {
      origin: Partial<Location>
      destination: Partial<Location>
      containers: Partial<Container>[] | null
      cargo_components: Partial<CargoComponent>[] | null
      line_items: Partial<LineItem>[] | null
      shipment_stages: Partial<ShipmentStage>[] | null
    }>{}

    let quote: Partial<QuotePayload> = cloneDeep(quoteData)

    const omitFromQuote: (keyof Quote)[] = [
      'id', '$id', 'accepted_at', 'created_at', 'deleted_at',
      'published_at', 'reference', 'total_volume', 'total_weight', 'updated_at', 'inquiry',
      'shipment_id', 'shipment', 'source', 'tags'
    ]
    const omitFromOrigin: (keyof Location)[] = ['id', 'created_at', 'deleted_at', 'updated_at']
    const omitFromDestination: (keyof Location)[] = ['id', 'created_at', 'deleted_at', 'updated_at']
    const pickFromContainer: (keyof Container)[] = ['container_type', 'quantity']
    const pickFromCargoComponent: (keyof CargoComponent)[] = ['weight', 'height', 'width', 'depth', 'quantity']
    const omitFromLineItem: (keyof LineItem)[] = [
      'key', 'id', 'created_at', 'deleted_at', 'updated_at', 'quote_id', 'converted_price_cents_ex']

    if (quote.shipment_stages) {
      quote.shipment_stages = this.filterShipmentStages(quote.shipment_stages, quote.line_items)
    }

    quote = omit(quote, omitFromQuote)
    quote.origin = omit(quote.origin, omitFromOrigin)
    quote.destination = omit(quote.destination, omitFromDestination)
    if (Array.isArray(quoteData.containers) && quoteData.containers.length > 0) {
      quoteData.containers = quoteData.containers.filter(n => n.container_type !== ContainerTypes.UNSPECIFIED)
      quote.containers = quoteData.containers.map(ctr => pick(ctr, pickFromContainer))
    } else {
      delete quote.containers
    }

    if (Array.isArray(quoteData.cargo_components) && quoteData.cargo_components.length > 0) {
      quote.cargo_components = quoteData.cargo_components.map(item => pick(item, pickFromCargoComponent))
    } else {
      delete quote.cargo_components
    }

    if (Array.isArray(quoteData.line_items) && quoteData.line_items.length > 0) {
      quote.line_items = quoteData.line_items.map((le) => {
        const ple: Partial<LineItem> = omit(le, omitFromLineItem)
        ple.price_cents_ex = Math.ceil(le.price_cents_ex)
        return ple
      })
    } else {
      delete quote.line_items
    }

    quote.agent_fee_euro_cents = (quote.agent_fee_euro_cents &&
        Math.round(quote.agent_fee_euro_cents)) || 0
    quote.total_price_euro_cents_ex = (quote.total_price_euro_cents_ex &&
        Math.round(quote.total_price_euro_cents_ex)) || 0
    return quote
  }

  /**
   * Remove data from quote that should not be sent to the API
   */
  public static sanitizePriceUpdate (priceUpdateData: Partial<Quote>) {
    interface PriceUpdatePayload extends Modify<Partial<Quote>, {
      line_items: Partial<LineItem>[] | null
      shipment_stages: Partial<ShipmentStage>[] | null
    }>{}

    let priceUpdate: Partial<PriceUpdatePayload> = cloneDeep(priceUpdateData)
    const pickFromPriceUpdate: Array<keyof Quote> = [
      'shipment_id', 'expiration_date', 'valid_from', 'total_price_euro_cents_ex', 'total_price_euro_cents_inc',
      'total_vat', 'agent_fee_euro_cents', 'port_of_loading', 'line_items', 'status', 'notes', 'shipment_stages'
    ]

    const pickFromLineItem: (keyof LineItem)[] = [
      'quantity', 'unit', 'unit_price_cents', 'price_cents_ex', 'currency', 'description', 'shipment_stage',
      'rank', 'vat_category', 'vat_rate'
    ]

    priceUpdate = pick(priceUpdate, pickFromPriceUpdate)

    if (Array.isArray(priceUpdate.line_items) && priceUpdate.line_items.length > 0) {
      priceUpdate.line_items = priceUpdate.line_items.map((lineItem) => {
        const ple = pick(lineItem, pickFromLineItem)
        if (ple.price_cents_ex) { // Ok, typescript, I'll do it your way
          ple.price_cents_ex = Math.round(ple.price_cents_ex)
        }
        return ple
      })
    } else {
      delete priceUpdate.line_items
    }
    priceUpdate.agent_fee_euro_cents = (priceUpdate.agent_fee_euro_cents &&
      Math.round(priceUpdate.agent_fee_euro_cents)) || 0
    priceUpdate.total_price_euro_cents_ex = (priceUpdate.total_price_euro_cents_ex &&
      Math.round(priceUpdate.total_price_euro_cents_ex)) || 0

    if (priceUpdate.shipment_stages) {
      priceUpdate.shipment_stages = this.filterShipmentStages(priceUpdate.shipment_stages, priceUpdate.line_items)
      // Delete the shipment stages if they are null, to not accidentally overwrite those of the shipment
      // when publishing the price update
      if (!priceUpdate.shipment_stages.length) {
        delete priceUpdate.shipment_stages
      }
    } else {
      // Delete the shipment stages if they are null, to not accidentally overwrite those of the shipment
      // when publishing the price update
      delete priceUpdate.shipment_stages
    }
    return priceUpdate
  }

  /** Remove shipment stages that are not assigned to any line item or do not have a forwarder assigned */
  static filterShipmentStages (shipmentStages: Partial<ShipmentStage>[], lineItems?: Partial<LineItem>[]|null) {
    if (!lineItems) {
      return []
    }
    const stagesInLineItems = lineItems.reduce((result, item) => {
      if (!isNil(item.shipment_stage) && !result.includes(item.shipment_stage)) {
        result.push(item.shipment_stage)
      }
      return result
    }, [] as ShipmentStages[])
    return shipmentStages.reduce((result, { forwarder_id, stage }) => {
      if (!isNil(stage) && stagesInLineItems.includes(stage) && !!forwarder_id) {
        result.push({ forwarder_id, stage })
      }
      return result
    }, [] as Partial<ShipmentStage>[])
  }

  static deleteGenerated (inquiryId: Inquiry['id']) {
    return this.delete(quote => quote.inquiry_id === inquiryId &&
      quote.source === QuoteSource.QuoteEngine)
  }

  get forwarders () {
    if (this.shipment_stages?.length) {
      const fwders: Record<Organization['id'], Organization> = {}
      for (const stage of this.shipment_stages) {
        if (stage.forwarder) {
          fwders[stage.forwarder.id] = stage.forwarder
        }
      }
      return Object.values(fwders)
    }
    return []
  }

  get customs_agent () {
    if (this.shipment_stages && this.shipment_stages.length > 0) {
      const stage = this.shipment_stages.find(
        item => item.stage === ShipmentStages.SHIPMENT_STAGE_IMPORT_FORMALITIES
      )
      if (stage && stage.forwarder) {
        return stage.forwarder
      }
    }
  }

  get forwarder () {
    if (this.shipment_stages && this.shipment_stages.length > 0) {
      const stage = this.shipment_stages.find(
        item => item.stage === ShipmentStages.SHIPMENT_STAGE_CARRIAGE_TRANSIT
      )
      if (stage && stage.forwarder) {
        return stage.forwarder
      }
    }
  }

  getMinOrMaxTransitDays (type: 'min'|'max') {
    if (!this.carrier_routes || this.carrier_routes.length === 0) {
      return null
    }
    let result = null
    for (const carrierRoute of this.carrier_routes) {
      if (!carrierRoute.routes || carrierRoute.routes.length === 0) {
        continue
      }
      for (const route of carrierRoute.routes) {
        if (isNil(result) ||
          (type === 'min' && route.transit_days < result) ||
          (type === 'max' && route.transit_days > result)
        ) {
          result = route.transit_days
        }
      }
    }
    return result
  }

  get min_transit_days_for_sorting () {
    const mtd = this.getMinOrMaxTransitDays('min')
    return mtd === 0 ? 9999 : mtd
  }

  get max_transit_days () {
    return this.getMinOrMaxTransitDays('max')
  }

  get rating () {
    const emptyRating = { score: 0, count: 0 }

    if (!this.shipment_stages?.length || this.shipment_stages.some(stage =>
      stage.forwarder && !stage.forwarder.aggregate_score?.count)) {
      return emptyRating
    }

    // First get the scores for each participating forwarder, eliminating duplicates
    const fwdScores = this.shipment_stages.reduce<Record<Organization['id'], AggregateScore>>((result, stage) => {
      if (stage.forwarder?.aggregate_score) {
        result[stage.forwarder.id] = stage.forwarder.aggregate_score
      }
      return result
    }, {})

    const rating = Object.values(fwdScores).reduce((result, score) => {
      result.score += score.total
      result.count += score.count
      return result
    }, emptyRating)
    return rating
  }
}
