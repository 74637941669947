<template>
  <v-row dense>
    <v-col v-if="$vuetify.breakpoint.xsOnly" cols="12" class="justify-center d-flex">
      <slot />
    </v-col>
    <v-col>
      <v-row class="mr-1 d-flex justify-space-between">
        <v-col cols="auto">
          <v-btn
            v-if="showBackButton"
            :disabled="backDisabled"
            text
            data-cy="decreasePage"
            @click="$emit('decrease-page')"
          >
            <v-icon left>
              {{ mdiChevronLeft }}
            </v-icon>
            <span>
              <span>{{ backText ? backText : $t('buttons.back') }}</span>
            </span>
          </v-btn>
        </v-col>
        <v-col v-if="$vuetify.breakpoint.smAndUp" class="justify-center d-flex">
          <slot />
        </v-col>
        <v-col cols="auto">
          <v-btn
            :disabled="nextDisabled"
            :color="color"
            class="white--text"
            depressed
            data-cy="increasePage"
            @click="$emit('increase-page')"
          >
            <span>
              <span>{{ nextText ? nextText : $t('buttons.next') }}</span>
            </span>
            <v-icon right>
              {{ mdiChevronRight }}
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { mdiChevronLeft, mdiChevronRight } from '@mdi/js'
import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  name: 'BackNextButtons',
  props: {
    showBackButton: {
      type: Boolean,
      default: true
    },
    nextDisabled: {
      type: Boolean,
      default: false
    },
    backDisabled: {
      type: Boolean,
      default: false
    },
    nextText: {
      type: String,
      default: ''
    },
    backText: {
      type: String,
      default: ''
    },
    color: {
      type: String,
      default: 'primary'
    }
  },
  setup () {
    return { mdiChevronLeft, mdiChevronRight }
  }
})
</script>
