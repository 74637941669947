import { Model } from '@vuex-orm/core'
import { Config } from '@vuex-orm/plugin-axios'
import User from './user'
import Organization from './organization'
import { ADMIN, FORWARDER, MESSAGE } from '~/config/api'
import { UserRole } from '~/composables/enums'

export interface Correspondent {
  user_id: string
  user?: User
  organization_id: string
  organization?: Organization
  name: string
  email: string
}

export enum Tag {
  customs = 'customs',
  chat = 'chat',
}

export interface Attachment {
  id: string
  name: string
  media_type: string
  file?: File
  url?: string
}

const endpoints: Record<UserRole, string> = {
  [UserRole.CLIENT]: MESSAGE,
  [UserRole.ADMIN]: ADMIN.MESSAGE,
  [UserRole.FORWARDER]: FORWARDER.MESSAGE
}

export default class PlatformMessage extends Model {
  static entity = 'platformMessages';

  static fields () {
    return {
      id: this.attr(null),
      cargoplot_reference: this.string(''),
      body: this.string(''),
      sender: this.attr(null),
      receiver: this.attr(null),
      send_time: this.string(''),
      edit_time: this.string(''),
      tags: this.attr([]),
      attachments: this.attr([])
    }
  }

  id!: string;
  cargoplot_reference!: string;
  body!: string;
  sender!: Correspondent;
  receiver?: Correspondent|null;
  send_time!: string;
  edit_time!: string;
  tags?: Tag[]|null;
  attachments?: Attachment[]|null;

  static fetch (cargoplotRef: string, config?: Config, role = UserRole.CLIENT) {
    return this.api().get(
      `${endpoints[role]}`,
      {
        ...config,
        params: {
          ...config?.params,
          ref: cargoplotRef
        }
      }
    )
  }

  static get (cargoplotRef: string, tags: string[] = []) {
    const q = this.query()
      .where('cargoplot_reference', cargoplotRef)
      .orderBy('send_time', 'asc')
    if (tags?.length) {
      q.where('tags', (v: string[]) => v.some(tag => tags.includes(tag)))
    }
    return q.get()
  }

  static persist (payload: PlatformMessage, config?: Config, role = UserRole.CLIENT) {
    if (payload.id) {
      const { body } = payload
      return this.api().put(`${endpoints[role]}/${payload.id}`, { body }, config)
    } else {
      const { cargoplot_reference, body, tags, attachments } = payload
      const formData = new FormData()
      formData.append('body', JSON.stringify({ cargoplot_reference, body, tags }))
      attachments?.forEach((attachment) => {
        if (attachment.file) {
          formData.append('attachments', attachment.file, attachment.name)
        }
      })
      return this.api().post(`${endpoints[role]}`, formData, { ...config, headers: { 'Content-Type': 'multipart/form-data' } })
    }
  }

  static markAsRead (id: string, role = UserRole.CLIENT) {
    return this.api().post(`${endpoints[role]}/${id}/viewed`)
  }

  static downloadAttachment (msgId: string, attachmentId: string, config?: Config, role = UserRole.CLIENT) {
    const headers = {
      'Content-Type': 'application/octet-stream'
    }
    return PlatformMessage.api().get(`${endpoints[role]}/${msgId}/attachment/${attachmentId}`,
      { ...config, headers, save: false })
  }
}

export class ReadStatus extends Model {
  static entity = 'readStatuses'
  static primaryKey = 'cargoplot_reference'

  static fields () {
    return {
      cargoplot_reference: this.attr(null),
      user_last_read_id: this.string(''),
      user_unread: this.number(0),
      organization_last_read_id: this.string(''),
      organization_unread: this.number(0)
    }
  }

  cargoplot_reference!: string;
  user_last_read_id!: string;
  user_unread!: number;
  organization_last_read_id!: string;
  organization_unread!: number;
}
