














































import { defineComponent, computed } from '@nuxtjs/composition-api'
import { mdiAccount, mdiLogout } from '@mdi/js'
import { useI18n } from '~/composables/hooks'
import { useVuexPathify } from '~/composables/hooks/store'
import NavLink from '~/components/App/NavLink'
import User from '~/models/user'
import { useAuth } from '~/composables/helpers/auth'
import { NavItem } from '~/composables/types'

export default defineComponent({
  name: 'UserMenu',
  components: { NavLink },
  setup () {
    const { i18n } = useI18n()
    const { get } = useVuexPathify()
    const auth = useAuth()

    const loggedInUser = get<User>('loggedInUser')
    const username = computed(() => (
      loggedInUser.value && `${loggedInUser.value.first_name} ${loggedInUser.value.last_name}`) ?? auth.user.nickname
    )

    const profileItem = computed<NavItem>(() => ({
      icon: mdiAccount,
      title: i18n.t('nav.profile'),
      to: {
        name: 'profile'
      }
    }))

    return { username, profileItem, mdiLogout }
  }
})
