/* eslint-disable camelcase */
import { Model } from '@vuex-orm/core'
import { pick } from 'lodash-es'
import { DocumentType, DocumentSlotStatus, UserRole } from '~/composables/enums'
import User from '~/models/user'
import Shipment from '~/models/shipment'
import Document from '~/models/document'
import { ADMIN, FORWARDER, DOCSLOT } from '~/config/api'

const endpoints: Record<UserRole, string> = {
  [UserRole.CLIENT]: DOCSLOT,
  [UserRole.ADMIN]: ADMIN.DOCSLOT,
  [UserRole.FORWARDER]: FORWARDER.DOCSLOT
}

export default class DocumentSlot extends Model {
  // This is the name used as module name of the Vuex Store.
  static entity = 'documentSlots'

  static fields () {
    return {
      id: this.attr(null),
      created_at: this.attr(''),
      updated_at: this.attr(''),
      deleted_at: this.attr(null),
      label: this.attr(null),
      type: this.number(DocumentType.OTHER),
      status: this.attr(null),
      due_date: this.attr(null),
      provided_on: this.attr(null),
      rejection_reason: this.attr(null),
      shipment_id: this.number(0),
      document_id: this.attr(null),
      user_id: this.number(0),
      shipment: this.belongsTo(Shipment, 'shipment_id'),
      document: this.belongsTo(Document, 'document_id'),
      user: this.belongsTo(User, 'user_id')
    }
  }

  id!: number
  created_at!: string
  updated_at!: string
  deleted_at?: string | null
  label?: string
  type!: DocumentType
  status!: DocumentSlotStatus
  due_date?: string|null
  provided_on?: string
  rejection_reason?: string
  shipment_id!: number
  shipment?: Shipment
  document?: Document
  document_id?: number
  user_id!: number
  user?: User

  static make (payload: DocumentSlot, role = UserRole.CLIENT, config = {}) {
    return this.api().post(
      endpoints[role],
      this.sanitize(payload, role, true),
      config
    )
  }

  public async persist (payload: DocumentSlot, role = UserRole.CLIENT, config = {}) {
    const originalRecord = this.$toJson()
    try {
      await this.$update(payload)
      return await DocumentSlot.api().put(
        `${endpoints[role]}/${this.id}`,
        DocumentSlot.sanitize(payload, role),
        config
      )
    } catch (e) {
      this.$update(originalRecord)
      throw e
    }
  }

  public static remove (id: number, role = UserRole.CLIENT) {
    return DocumentSlot.api().delete(`${endpoints[role]}/${id}`, { delete: id })
  }

  static sanitize (item: DocumentSlot, role: UserRole, withShipmentId = false) {
    let picked: string[] = []
    if (withShipmentId) {
      picked = ['shipment_id']
    }
    const clientFields = [...picked, 'type', 'label', 'document_id']
    const forwarderFields = [...clientFields, 'status', 'due_date', 'rejection_reason']
    const adminFields = [...forwarderFields]

    const fields = {
      [UserRole.CLIENT]: clientFields,
      [UserRole.ADMIN]: adminFields,
      [UserRole.FORWARDER]: forwarderFields
    }[role]
    const sanitized = pick(item, fields)
    if (Object.prototype.hasOwnProperty.call(sanitized, 'due_date') &&
      typeof sanitized.due_date === 'undefined') {
      sanitized.due_date = null
    }
    return sanitized
  }
}
