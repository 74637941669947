













import { defineComponent, reactive } from '@nuxtjs/composition-api'
import { StatusFields } from './index'
import { useI18n, useNotify, useRole } from '~/composables/hooks'
import { processErrors } from '~/composables/errors'
import Review from '~/models/review'
import { UserRole } from '~/composables/enums'
import { Event } from '~/composables/helpers/gtm'

export default defineComponent({
  name: 'ReviewInteractor',
  setup (_, { emit }) {
    const notify = useNotify()
    const { i18n } = useI18n()
    const userRole = useRole()

    const loadingStatus = reactive<StatusFields>({
      retract: false,
      save: false,
      delete: false,
      postpone: false,
      refuse: false
    })

    const retract = async (id: number) => {
      try {
        loadingStatus.retract = true
        const response = await Review.retract(id)
        const retractedReview = response.getDataFromResponse()
        emit('retracted:review', retractedReview, Event.REVIEW_RETRACTED)
        notify({
          color: 'success',
          message: i18n.t('reviews.status.retract')
        })
      } catch (e) {
        processErrors(e, notify)
      } finally {
        loadingStatus.retract = false
      }
    }

    const deleteReview = async (id: number) => {
      try {
        loadingStatus.delete = true
        const response = await Review.deleteReview(id)
        const deletedReview = response.getDataFromResponse()
        emit('deleted:review', deletedReview, Event.REVIEW_DELETED)
        notify({
          color: 'success',
          message: i18n.t('reviews.status.delete')
        })
        return response
      } catch (e) {
        processErrors(e, notify)
      } finally {
        loadingStatus.delete = false
      }
    }

    const persist = async (payload: Review, notification = true, progress = true, throwError = false, role = userRole) => {
      try {
        if (progress) {
          loadingStatus.save = true
        }
        if (payload.id) {
          const review = await Review.find(payload.id)
          if (review === null) {
            throw new Error(i18n.t('reviews.error.notFound').toString())
          }
          const response = await review.persist(payload, {}, role)
          const updatedReview = response.getDataFromResponse()
          emit('updated:review', updatedReview, Event.REVIEW_UPDATED)
          if (notification) {
            notify({
              color: 'success',
              message: i18n.t('reviews.status.updated')
            })
          }
          return response
        } else {
          const response = await Review.make(payload, {}, userRole)
          const review = response.getDataFromResponse()
          emit('created:review', review, Event.REVIEW_SUBMISSION)
          if (notification) {
            notify({
              color: 'success',
              message: i18n.t('reviews.status.created')
            })
          }
          return response
        }
      } catch (e) {
        if (throwError) {
          throw e
        }
        processErrors(e, notify)
      } finally {
        if (progress) {
          loadingStatus.save = false
        }
      }
    }

    const postpone = async (payload: Review, role: UserRole) => {
      try {
        loadingStatus.postpone = true
        const response = await persist(payload, false, false, false, role)
        emit('postponed:review', response?.getDataFromResponse())
      } catch (e) {
        processErrors(e, notify)
      } finally {
        loadingStatus.postpone = false
      }
    }

    const refuse = async (payload: Review, role: UserRole) => {
      try {
        loadingStatus.refuse = true
        const response = await persist(payload, false, false, false, role)
        emit('refused:review', response?.getDataFromResponse())
      } catch (e) {
        processErrors(e, notify)
      } finally {
        loadingStatus.refuse = false
      }
    }

    return { loadingStatus, retract, persist, deleteReview, postpone, refuse }
  }
})
