import { Model } from '@vuex-orm/core'
import { Config } from '@vuex-orm/plugin-axios'
import User from './user'
import { ADMIN, FORWARDER, MESSAGE } from '~/config/api'
import { UserRole } from '~/composables/enums'

export enum Tag {
  arrival = 'arrival',
  departure = 'departure',
}

export interface Attachment {
  id: string
  name: string
  media_type: string
  file?: File
  url?: string
}

const endpoints: Record<UserRole, string> = {
  [UserRole.CLIENT]: MESSAGE,
  [UserRole.ADMIN]: ADMIN.NOTE,
  [UserRole.FORWARDER]: FORWARDER.NOTE
}

export default class Note extends Model {
  static entity = 'notes';

  static fields () {
    return {
      id: this.attr(null),
      user_id: this.number(null),
      last_touched_user_id: this.number(null),
      contents: this.string(''),
      create_time: this.string(''),
      edit_time: this.string(''),
      delete_time: this.string(''),
      tags: this.attr([]),
      attachments: this.attr([]),

      shipment_id: this.number(null),
      organization_id: this.number(null),
      invoice_id: this.number(null),

      creator: this.attr(null),
      editor: this.attr(null)
    }
  }

  id!: string
  user_id!: number
  last_touched_user_id!: number
  contents!: string
  create_time!: string
  edit_time!: string
  delete_time!: string
  tags?: Tag[]|null
  attachments?: Attachment[]|null

  shipment_id?: number
  organization_id?: number
  invoice_id?: number;

  creator?: User;
  editor?: User;

  static fetch (entity: string, entityId: string|number, config?: Config, role = UserRole.CLIENT) {
    return this.api().get(`${endpoints[role]}/${entity}/${entityId}`, config)
  }

  static get (entity: string, entityId: string|number, tags: string[] = []) {
    const q = this.query()
      .where(`${entity}_id`, entityId)
      .where('delete_time', '')
      .orderBy('create_time', 'desc')
    if (tags?.length) {
      q.where('tags', (v: string[]) => v.some(tag => tags.includes(tag)))
    }
    return q.get()
  }

  static async persist (entity: string, entityId: string|number, payload: Note, config?: Config, role = UserRole.CLIENT) {
    if (payload.id) {
      const originalRecord = Note.find(payload.id)
      if (!originalRecord) {
        throw new Error('Record not found')
      }
      const originalRecordData = originalRecord.$toJson()
      try {
        Note.update({ where: payload.id, data: { contents: payload.contents } })
        return await this.api().patch(`${endpoints[role]}/${payload.id}`, { contents: payload.contents }, config)
      } catch (e) {
        Note.update({ where: payload.id, data: originalRecordData })
        throw e
      }
    } else {
      const { contents, tags, attachments } = payload
      const formData = new FormData()
      formData.append('body', JSON.stringify({ contents, tags }))
      attachments?.forEach((attachment) => {
        if (attachment.file) {
          formData.append('attachments', attachment.file, attachment.name)
        }
      })
      return this.api().post(`${endpoints[role]}/${entity}/${entityId}`, formData, { ...config, headers: { 'Content-Type': 'multipart/form-data' } })
    }
  }

  static remove (noteId: string, config?: Config, role = UserRole.CLIENT) {
    return this.api().delete(`${endpoints[role]}/${noteId}`, { delete: noteId, ...config })
  }

  static downloadAttachment (msgId: string, attachmentId: string, config?: Config, role = UserRole.CLIENT) {
    const headers = { 'Content-Type': 'application/octet-stream' }
    return Note.api().get(`${endpoints[role]}/${msgId}/attachment/${attachmentId}`,
      { ...config, headers, save: false })
  }
}
