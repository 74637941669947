import { getCurrentInstance, wrapProperty, provide } from '@nuxtjs/composition-api'
import { intersection } from 'lodash-es'
import { parseJwt, JWTData } from '../jwt'
import { useNotify } from '~/composables/hooks'

let currentToken = ''
let jwtData: Partial<JWTData>

export interface Actions {
  read: boolean,
  write: boolean,
  delete?: boolean
}

export enum Mode {
  OR = 'or',
  AND = 'and'
}

export enum Resources {
  INQUIRIES = 'inquiries',
  SHIPMENTS = 'shipments',
  SHIPMENTSFORWARDER = 'shipmentsForwarder',
  ORGANIZATIONS = 'organizations',
  USERS = 'users',
  QUOTES = 'quotes',
  PRICEUPDATES = 'priceUpdates',
  INVOICES = 'invoices',
  DOCUMENTS = 'documents',
  REVIEWS = 'reviews',
  DOCUMENTSFORWARDER = 'documentsForwarder',
}

export type Permissions = Record<Resources, Actions>

export const useAuth = wrapProperty('$auth', false)

/**
 * Returns true if the user has the requested permissions. This is determined by the permissions
 * stored in the access token.
 *
 * @export
 * @param {(string|string[])} requestedPermissions
 * @param {*} [mode=Mode.OR]
 * @return {*}  {boolean}
 */
export function userCan (requestedPermissions: string|string[], mode = Mode.OR): boolean {
  const auth = useAuth()
  const token = auth.strategy.token.get()
  if (token !== currentToken) {
    jwtData = parseJwt(token)
    currentToken = token
  }

  if (typeof requestedPermissions === 'string') {
    requestedPermissions = [requestedPermissions]
  }

  if (mode === Mode.OR) {
    return intersection(requestedPermissions, jwtData.permissions).length > 0
  }
  return intersection(requestedPermissions, jwtData.permissions).length === requestedPermissions.length
}

/**
 * Redirects user back to index (dashboard) if user is unauthorized to view the current page
 * Returns true if user is being redirected, and false otherwise
 *
 * @export
 * @param {(string|string[])} requestedPermissions
 * @return {*}  {boolean}
 */
export function redirectIfUnauthorized (requestedPermissions: boolean): boolean {
  const { $router, localePath } = getCurrentInstance()!.proxy
  const notify = useNotify()
  if (!requestedPermissions) {
    notify({ message: 'Access denied', color: 'warning' })
    $router.replace(localePath({ name: 'index' }))
    return true
  }
  return false
}

export function providePermissions () {
  const permissions: Permissions = {
    [Resources.INQUIRIES]: {
      read: userCan('read:inquiries'),
      write: userCan('write:inquiries'),
      delete: userCan('delete:inquiries')
    },
    [Resources.SHIPMENTS]: {
      read: userCan('read:shipments'),
      write: userCan('write:shipments')
    },
    [Resources.SHIPMENTSFORWARDER]: {
      read: userCan('read:shipments-forwarder'),
      write: userCan('write:shipments-forwarder')
    },
    [Resources.ORGANIZATIONS]: {
      read: userCan('read:organizations'),
      write: userCan('write:organizations'),
      delete: userCan('delete:organizations')
    },
    [Resources.USERS]: {
      read: userCan('read:users'),
      write: userCan('write:users')
    },
    [Resources.QUOTES]: {
      read: userCan('read:quotes'),
      write: userCan('write:quotes'),
      delete: userCan('delete:quotes')
    },
    [Resources.PRICEUPDATES]: {
      read: userCan('read:price-updates'),
      write: userCan('write:price-updates'),
      delete: userCan('delete:price-updates')
    },
    [Resources.INVOICES]: {
      read: userCan('read:invoices'),
      write: userCan('write:invoices'),
      delete: userCan('delete:invoices')
    },
    [Resources.DOCUMENTS]: {
      read: userCan('read:documents'),
      write: userCan('write:documents')
    },
    [Resources.REVIEWS]: {
      read: userCan('read:reviews'),
      write: userCan('write:reviews')
    },
    [Resources.DOCUMENTSFORWARDER]: {
      read: userCan('read:documents-forwarder'),
      write: userCan('write:documents-forwarder')
    }
  }

  provide('permissions', permissions)
  return permissions
}
