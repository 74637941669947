import { provide, inject, watch } from '@nuxtjs/composition-api'
import { UseWebSocketReturn, useWebSocket } from '@vueuse/core'
import { WEBSOCKET } from '~/config/api'
import Inquiry from '~/models/inquiry'
import Invoice from '~/models/invoice'
import PlatformMessage, { ReadStatus } from '~/models/message'
import Note from '~/models/note'
import Organization from '~/models/organization'
import Quote from '~/models/quote'
import Shipment from '~/models/shipment'
import Task, { TasksCount } from '~/models/task'

export const websocketSymbol = Symbol('WEBSOCKET')

export enum WebSocketEventType {
  Inquiry = 'Inquiry',
  Shipment = 'Shipment',
  Quote = 'Quote',
  Invoice = 'Invoice',
  PlatformMessage = 'PlatformMessage',
  PlatformMessageRead = 'PlatformMessage:viewed',
  ReadStatus = 'ReadStatus',
  Organization = 'Organization',
  Note = 'Note',
  Task = 'Task',
  TasksCount = 'TasksCount'
}

export interface WebsocketEvent {
  id?: string,
  event: WebSocketEventType,
  data: any
}

export function provideWebsocket () {
  const protocol = window.location.protocol === 'https:' ? 'wss:' : 'ws:'
  const wsUrl = `${protocol}//${window.location.host}/api${WEBSOCKET}`
  const ws = useWebSocket(wsUrl, {
    autoReconnect: true,
    onDisconnected (_ws, event) {
      console.log('Websocket disconnected:', event.code, event.reason)
    }
  })

  watch(ws.data, async (data) => {
    let jsonMessage: WebsocketEvent | undefined
    try {
      jsonMessage = JSON.parse(data)
    } catch {}

    if (typeof jsonMessage !== 'undefined') {
      try {
        switch (jsonMessage.event) {
          case WebSocketEventType.Inquiry:
            await Inquiry.insertOrUpdate({ data: jsonMessage.data })
            break
          case WebSocketEventType.Quote:
            await Quote.insertOrUpdate({ data: jsonMessage.data })
            break
          case WebSocketEventType.Shipment:
            await Shipment.insertOrUpdate({ data: jsonMessage.data })
            break
          case WebSocketEventType.Invoice:
            await Invoice.insertOrUpdate({ data: jsonMessage.data })
            break
          case WebSocketEventType.PlatformMessage:
            await PlatformMessage.insertOrUpdate({ data: jsonMessage.data })
            break
          case WebSocketEventType.ReadStatus:
            await ReadStatus.insertOrUpdate({ data: jsonMessage.data })
            break
          case WebSocketEventType.Organization:
            await Organization.insertOrUpdate({ data: jsonMessage.data })
            break
          case WebSocketEventType.Note:
            await Note.insertOrUpdate({ data: jsonMessage.data })
            break
          case WebSocketEventType.Task:
            await Task.insertOrUpdate({ data: jsonMessage.data })
            break
          case WebSocketEventType.TasksCount:
            await TasksCount.insertOrUpdate({ data: jsonMessage.data })
            break
        }
      } catch (error) { }
    }
  })
  provide(websocketSymbol, ws)
}

export function useConnectedWebsocket () {
  const ws = inject<UseWebSocketReturn<any>>(websocketSymbol)
  if (!ws) {
    throw new Error('useWebsocket() called without provider.')
  }
  return ws
}
