<template>
  <v-app>
    <v-container class="fill-height">
      <v-row class="align-center justify-center fill-height flex-column">
        <nuxt />
      </v-row>
    </v-container>
    <notification-box />
  </v-app>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api'
import NotificationBox from '~/components/NotificationBox'

export default defineComponent({
  components: { NotificationBox },
  data () {
    return {}
  },
  head () {
    return {
      ...this.$nuxtI18nHead({ addSeoAttributes: true }),
      script: [
        {
          src: `https://maps.googleapis.com/maps/api/js?key=${this.$config.googleCloudAPIKey}&libraries=places`,
          defer: true
        },
        {
          src: `//js.hs-scripts.com/${this.$config.hubspotAppKey}.js`,
          defer: true,
          async: true,
          type: 'text/javascript',
          id: 'hs-script-loader'
        }
      ]
    }
  }
})
</script>
