









































import { defineComponent, computed, PropType, ref, watch } from '@nuxtjs/composition-api'
import { StatusFields } from '~/components/Containers/ReviewInteractor'
import { useI18n } from '~/composables/hooks'
import { useDeepSync } from '~/composables/hooks/propsync'

export default defineComponent({
  name: 'ReviewsListItemButtons',
  props: {
    currentPage: {
      type: Number,
      required: true
    },
    valuesEmpty: {
      type: Boolean,
      default: true
    },
    loadingButtons: {
      type: Object as PropType<StatusFields>,
      default: (): StatusFields => ({
        save: false,
        postpone: false,
        refuse: false
      })
    },
    disabledButtons: {
      type: Object as PropType<StatusFields>,
      default: (): StatusFields => ({
        save: false,
        postpone: false,
        refuse: false
      })
    }
  },
  setup (props, { emit }) {
    const { i18n } = useI18n()
    const deepSync = useDeepSync(props, emit)
    const isFirstPage = computed(() => props.currentPage === 1)
    const disabled = deepSync('disabledButtons')
    const disableCloseBtn = ref(false)
    const nextButtonText = computed(() =>
      isFirstPage.value
        ? i18n.t('reviews.notificationsDialog.buttons.save')
        : i18n.t('common.submit')
    )

    watch(
      () => props.loadingButtons,
      (status) => {
        // If any buttons loadingStatus is true, disable all other buttons.
        if (Object.values(status).includes(true)) {
          const newDisabledStatus: StatusFields = { ...status }
          let key: keyof typeof status
          for (key in status) {
            newDisabledStatus[key] = !status[key]
          }
          disabled.value = newDisabledStatus
          disableCloseBtn.value = true
        } else {
          disabled.value = { ...props.disabledButtons }
          disableCloseBtn.value = false
        }
      }, {
        deep: true
      }
    )

    const next = () => {
      isFirstPage.value
        ? emit('save:overall')
        : props.valuesEmpty
          ? emit('skip')
          : emit('save:all')
    }

    return {
      isFirstPage,
      disableCloseBtn,
      disabled,
      nextButtonText,
      next
    }
  }
})
