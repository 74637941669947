














import { defineComponent } from '@nuxtjs/composition-api'
import { mdiSend } from '@mdi/js'
import { useSync } from '~/composables/hooks/propsync'

export default defineComponent({
  name: 'ReviewsScore',
  props: {
    title: {
      type: String,
      required: true
    },
    caption: {
      type: String,
      required: true
    },
    score: {
      type: Number,
      default: 0
    }
  },
  setup (props, { emit }) {
    const propsync = useSync(props, emit)
    const localValue = propsync('score')
    return { localValue, mdiSend }
  }
})
