var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{class:("text-caption text-sm-body-2 align-center " + _vm.flexJustify),attrs:{"dense":_vm.$vuetify.breakpoint.xsOnly}},[_c('v-col',[_c('v-icon',{attrs:{"left":"","color":_vm.labelColor},domProps:{"textContent":_vm._s(_vm.cargoTypeIcon(_vm.item.cargo_type))}}),_vm._v("\n    "+_vm._s(_vm.total)+" "+_vm._s(_vm.$t(("cargoType." + (_vm.item.cargo_type))))+"\n  ")],1),_vm._v(" "),(_vm.item.cargo_type !== 'containers')?[_c('v-col',[_c('v-icon',{staticClass:"mr-1",attrs:{"color":_vm.labelColor}},[_vm._v("\n        "+_vm._s(_vm.mdiWeightKilogram)+"\n      ")]),_vm._v("\n      "+_vm._s(!_vm.showEditingValue && _vm.item.total_weight ? _vm.item.total_weight.toLocaleString(
        _vm.localeISO,
        {
          style: 'decimal',
          maximumFractionDigits: 2
        }) : _vm.tempTotalWeight)+" kg\n    ")],1),_vm._v(" "),_c('v-col',[_c('v-icon',{staticClass:"mr-1",attrs:{"color":_vm.labelColor}},[_vm._v("\n        "+_vm._s(_vm.mdiAxisArrow)+"\n      ")]),_vm._v("\n      "+_vm._s(!_vm.showEditingValue && _vm.item.total_volume ? _vm.item.total_volume.toLocaleString(
        _vm.localeISO,
        {
          style: 'decimal',
          maximumFractionDigits: 2
        }) : _vm.tempTotalVolume)+" m"),_c('sup',[_vm._v("3")])],1)]:(_vm.isQuote)?[(//@ts-ignore
        _vm.item.free_demurrage_days)?_c('v-col',[_vm._v("\n      "+_vm._s(// @ts-ignore
        _vm.item.free_demurrage_days)+" free demurrage days\n    ")]):_vm._e(),_vm._v(" "),(//@ts-ignore
        _vm.item.free_detention_days)?_c('v-col',[_vm._v("\n      "+_vm._s(// @ts-ignore
        _vm.item.free_detention_days)+" free detention days\n    ")]):_vm._e()]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }