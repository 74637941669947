
































































import { defineComponent, PropType, watch } from '@nuxtjs/composition-api'
import isEmpty from 'validator/es/lib/isEmpty'
import { cloneDeep, isEqual } from 'lodash-es'
import { mdiCheck } from '@mdi/js'
import isEmail from 'validator/lib/isEmail'
import { useDeepSync } from '~/composables/hooks/propsync'
import { useI18n } from '~/composables/hooks'
import User from '~/models/user'
import Organization from '~/models/organization'
import ShipmentsPerYearSelector from '~/components/Forms/ShipmentsPerYearSelector'

export default defineComponent({
  name: 'PersonalInformationPanel',
  components: {
    ShipmentsPerYearSelector
  },
  props: {
    user: {
      type: Object as PropType<User>,
      required: true
    },
    saving: {
      type: Boolean,
      default: false
    },
    errors: {
      type: Object,
      default: () => ({})
    },
    isProfilePage: {
      type: Boolean,
      default: false
    }
  },
  setup (props, { emit }) {
    const { i18n } = useI18n()
    const deepSync = useDeepSync(props, emit)
    const ssErrors = deepSync('errors')
    const localUser = deepSync('user')
    if (!localUser.value) {
      localUser.value = new User()
    }
    if (localUser.value && !localUser.value?.organization) {
      localUser.value.organization = new Organization()
    }
    watch(() => props.user, (newUserValue) => {
      if (localUser.value && newUserValue && !isEqual(newUserValue, localUser.value)) {
        const prevOrg = localUser.value.organization
        localUser.value = cloneDeep(newUserValue)
        if (!newUserValue.organization) {
          localUser.value.organization = prevOrg
        }
      }
    }, { deep: true })
    const validation = {
      first_name: (v: string) => !isEmpty(v.trim()) || i18n.t('user.form.validation.first_name'),
      last_name: (v: string) => !isEmpty(v.trim()) || i18n.t('user.form.validation.last_name'),
      phone: (v: string) => !isEmpty(v.trim()) || i18n.t('user.form.validation.phone'),
      company: (v: string) => !isEmpty(v.trim()) || i18n.t('user.form.validation.company'),
      email: (v: string) => (!!v && isEmail(v.trim())) || i18n.t('validation.email'),
      shipments_per_year: (v: string) => !!v || i18n.t('validation.required')
    }
    return {
      validation,
      localUser,
      ssErrors,
      mdiCheck
    }
  }
})
