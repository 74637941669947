/* eslint-disable camelcase */
import { Model } from '@vuex-orm/core'
import { Config } from '@vuex-orm/plugin-axios'
import { omit } from 'lodash-es'
import LeanCustomsDV from './leanCustomsDV'
import { DocumentType, MediaType, Source, UserRole } from '~/composables/enums'
import Organization from '~/models/organization'
import { ADMIN, FORWARDER, DOCUMENT } from '~/config/api'
import User from '~/models/user'

const endpoints: Record<UserRole, string> = {
  [UserRole.ADMIN]: ADMIN.DOCUMENT,
  [UserRole.CLIENT]: DOCUMENT,
  [UserRole.FORWARDER]: FORWARDER.DOCUMENT
}

export default class Document extends Model {
  // This is the name used as module name of the Vuex Store.
  static entity = 'documents'

  // List of all fields (schema) of the post model. `this.attr` is used
  // for the generic field type. The argument is the default value.
  static fields () {
    return {
      id: this.number(0),
      created_at: this.attr(''),
      updated_at: this.attr(''),
      deleted_at: this.attr(null),
      type: this.number(0),
      uri: this.attr(''),
      uploaded_on: this.attr(''),
      expiration_date: this.attr(''),
      file_name: this.attr(''),
      media_type: this.number(0),
      file: this.attr(''),
      customs_agent_id: this.number(0),
      customs_agent: this.belongsTo(Organization, 'customs_agent_id'),
      user_id: this.number(0),
      user: this.belongsTo(User, 'user_id'),
      organization_id: this.number(0),
      organization: this.belongsTo(Organization, 'organization_id')
    }
  }

  id!: number
  created_at!: string
  updated_at!: string
  deleted_at?: string
  type!: DocumentType
  uri?: string
  uploaded_on?: string
  expiration_date?: string
  file_name!: string
  media_type!: MediaType
  customs_agent_id?: number
  customs_agent?: Organization
  user_id?: number
  user?: User
  organization_id?: number
  organization?: Organization
  file?: File

  /**
   * Create a document (cargoplot employees)
   *
   * @static
   * @param {Config} config
   * @returns {Promise<Response>}
   * @memberof Document
   */
  public static createDocument (documentData: Partial<Document>, shipmentId: number, config?: Config, role = UserRole.CLIENT) {
    const data = { ...omit(documentData, 'file'), shipment_id: shipmentId }
    return Document.api().post(endpoints[role], data, config)
  }

  /**
   * Upload a document (cargoplot employees)
   *
   * @static
   * @param {Config} config
   * @param {File} data
   * @param {UserRole} [role='client']
   * @param {Source} [source='dashboard']
   * @returns {Promise<Response>}
   * @memberof Document
   */
  public static uploadDocument (data: File, id:string|number, config?: Config, role = UserRole.CLIENT, source = Source.DASHBOARD) {
    const formData = new FormData()
    formData.append('myFile', data)
    return this.api().post(`${endpoints[role]}/upload/${id}`, formData, {
      ...config,
      headers: {
        'Content-Type': 'multipart/form-data',
        'Cargoplot-Source': source
      }
    })
  }

  /**
   * Download a single document by ID
   * @static
   * @param id The ID of the document
   * @param config The axios or vuex-orm config parameters
   * @returns A response object containing the data
   * @memberof Document
   */
  public static downloadOne (id:string|number, config?: Config, role = UserRole.CLIENT) {
    const headers = {
      'Content-Type': 'application/octet-stream'
    }
    return this.api().get(`${endpoints[role]}/${id}`, { ...config, headers, save: false })
  }

  /**
   * Create a lean customs document with the given data
   * @param data The data of the document
   * @param config The axios or vuex-orm config parameters
   * @returns A binary response object containing the data
   * @memberof Document
   */
  public static createLeanCustomsDv (data: Partial<LeanCustomsDV>, locale: string, config? :Config) {
    return this.api().post(
      `/pdf/dv-leancustoms-${locale.substring(0, 2)}`,
      data,
      { ...config, responseType: 'arraybuffer', save: false }
    )
  }

  /**
   * Convert a mime type to the appropriate enum value
   */
  public static convertMediaType (key: string) {
    const map: Map<string, MediaType> = new Map()
    map.set('application/octet-stream', MediaType.UNSPECIFIED)
      .set('application/pdf', MediaType.PDF)
      .set('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', MediaType.DOCX)
      .set('application/msword', MediaType.DOCX)
      .set('application/vnd.openxmlformats-officedocument.wordprocessingml.document', MediaType.XLSX)
      .set('application/vnd.ms-excel', MediaType.XLSX)
      .set('text/plain', MediaType.TXT)
      .set('image/png', MediaType.PNG)
      .set('image/jpeg', MediaType.JPG)
      .set('image/gif', MediaType.GIF)

    return map.get(key)
  }
}
