import { inject, provide } from '@nuxtjs/composition-api'
import * as countries from 'i18n-iso-countries'
import { UserRole } from '../enums'
import { Permissions } from '../helpers/auth'

export function usePermissions () {
  const permissions = inject<Permissions>('permissions')
  if (!permissions) {
    throw new Error('Permissions not provided')
  }
  return permissions
}

export function useRole () {
  const role = inject<UserRole>('userRole')
  if (!role) {
    throw new Error('Role not provided')
  }
  return role
}

export const countriesSymbol = Symbol('countries')

export function provideCountries () {
  countries.registerLocale(require('i18n-iso-countries/langs/en.json'))
  countries.registerLocale(require('i18n-iso-countries/langs/nl.json'))
  provide(countriesSymbol, countries)
}

export function useCountries () {
  const countries = inject<any>(countriesSymbol)
  if (!countries) {
    throw new Error('Countries not provided')
  }
  return countries
}
