/* eslint-disable camelcase */
import { Model } from '@vuex-orm/core'
import Invoice from './invoice'

export default class Payment extends Model {
  // This is the name used as module name of the Vuex Store.
  static entity = 'payments'

  // List of all fields (schema) of the post model. `this.attr` is used
  // for the generic field type. The argument is the default value.
  static fields () {
    return {
      id: this.number(0),
      created_at: this.attr(''),
      updated_at: this.attr(''),
      deleted_at: this.attr(null),
      invoice_id: this.attr(null),
      invoice: this.belongsTo(Invoice, 'invoice_id'),
      source_id: this.attr(''),
      source: this.number(0),
      status: this.attr(''),
      description: this.attr(''),
      amount_euro_cents: this.number(0)
    }
  }

  id!: number
  created_at!: string
  updated_at!: string
  deleted_at?: string
  invoice_id?: number
  invoice?: Invoice
  source_id?: string
  source?: number
  status?: string
  description?: string
  amount_euro_cents?: number
}

export enum PaymentSource {
  SOURCE_UNSPECIFIED,
  SOURCE_MOLLIE
}

export enum PaymentStatus {
  STATUS_PAID = 'paid',
  STATUS_EXPIRED = 'expired',
  STATUS_FAILED = 'failed',
  STATUS_CANCELED = 'canceled'
}
