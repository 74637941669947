import { Model } from '@vuex-orm/core'
import { Config } from '@vuex-orm/plugin-axios'

export type Usage = 'saveAndSend'|'save'|'send'|'none'
export interface MetaInfo {
  field: string
  usage: Usage
}
export default class CargoplotModel extends Model {
  static getState () {
    return this.store().state[this.database().namespace][this.entity]
  }

  public static async fetch (
    endpoint: string,
    config?: Config,
    lastRequestTime?: MetaInfo,
    cursor?: MetaInfo
  ) {
    const params = { ...config?.params }

    if (lastRequestTime && ['saveAndSend', 'send'].includes(lastRequestTime.usage) && this.getState()[lastRequestTime.field]) {
      params.last_request_time = this.getState()[lastRequestTime.field]
    }
    if (cursor && ['saveAndSend', 'send'].includes(cursor.usage) && this.getState()[cursor.field]) {
      params.after = this.getState()[cursor.field]
    }

    const response = await this.api().get(endpoint, { ...config, params })

    if (lastRequestTime && ['saveAndSend', 'save'].includes(lastRequestTime.usage)) {
      this.commit((state) => {
        state[lastRequestTime.field] = response.response.data.meta?.requested_at ?? ''
      })
    }

    if (cursor && ['saveAndSend', 'save'].includes(cursor.usage)) {
      this.commit((state) => {
        state[cursor.field] = response.response.data.meta?.cursor?.after ?? ''
      })
    }
    return response
  }
}
