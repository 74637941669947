import colors from 'vuetify/lib/util/colors'
import { Iconfont } from 'vuetify/types/services/icons'

export default {
  icons: {
    iconfont: 'mdiSvg' as Iconfont
  },
  theme: {
    options: { customProperties: true },
    dark: false,
    themes: {
      dark: {
        primary: colors.blue.darken2,
        accent: colors.indigo.base,
        secondary: colors.amber.base,
        info: colors.teal.lighten1,
        warning: colors.amber.base,
        error: colors.deepOrange.accent4,
        success: colors.green.accent3
      },
      light: {
        primary: colors.blue.darken3,
        secondary: colors.blueGrey.lighten1,
        accent: colors.blue.base,
        inquiry: colors.blue.darken3,
        shipment: colors.green.darken2,
        'inquiry-accent': colors.indigo.lighten5,
        organization: colors.indigo.base
      }
    }
  }
}
