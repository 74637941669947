import { Model } from '@vuex-orm/core'
import { Config } from '@vuex-orm/plugin-axios'
import { pick } from 'lodash-es'
import User from './user'
import Shipment from './shipment'
import { ADMIN, FORWARDER, SHIPMENT, TASK } from '~/config/api'
import { ProtoDateTime } from '~/composables/types'
import { dateToProtoDateTime, protoDateTimeToDate } from '~/composables/util'
import { UserRole } from '~/composables/enums'

const endpoints: Record<'shipment'|'task', Record<UserRole, string>> = {
  shipment: {
    [UserRole.CLIENT]: SHIPMENT,
    [UserRole.ADMIN]: ADMIN.SHIPMENT,
    [UserRole.FORWARDER]: FORWARDER.SHIPMENT
  },
  task: {
    [UserRole.CLIENT]: TASK,
    [UserRole.ADMIN]: ADMIN.TASK,
    [UserRole.FORWARDER]: FORWARDER.TASK
  }
}
export default class Task extends Model {
  static entity = 'tasks';

  static fields () {
    return {
      id: this.attr(null),
      title: this.string(''),
      description: this.string(''),
      due_date_time: this.attr(null),
      create_time: this.string(''),
      update_time: this.string(''),
      delete_time: this.string(''),
      complete_time: this.string(''),

      shipment_id: this.number(null),
      user_id: this.number(null),
      assignee_id: this.number(null),
      updater_id: this.number(null),

      shipment: this.belongsTo(Shipment, 'shipment_id'),
      creator: this.belongsTo(User, 'user_id'),
      editor: this.belongsTo(User, 'updater_id'),
      assignee: this.belongsTo(User, 'assignee_id')
    }
  }

  id!: string
  title!: string
  description!: string
  due_date_time?: ProtoDateTime|null
  create_time!: string
  update_time?: string
  delete_time?: string
  complete_time?: string

  shipment_id!: number
  user_id!: number
  updater_id?: number
  assignee_id?: number

  shipment?: Shipment;
  creator?: User;
  editor?: User;
  assignee?: User;

  static fetch (shipmentId: number, config?: Config, role = UserRole.CLIENT) {
    return this.api().get(`${endpoints.shipment[role]}/${shipmentId}${TASK}`, config)
  }

  static get (shipmentId: number) {
    return this.query()
      .where('shipment_id', shipmentId)
      .where('delete_time', '')
      .with('creator|editor|assignee')
      .get()
  }

  static async persist (payload: Partial<Task>, config?: Config, role = UserRole.CLIENT) {
    const fields: (keyof Task)[] = ['title', 'description', 'due_date_time', 'assignee_id']
    const data = pick(payload, fields)
    if (payload.id) {
      const originalRecord = Task.find(payload.id)
      if (!originalRecord) {
        throw new Error('Record not found')
      }
      const originalRecordData = originalRecord.$toJson()
      try {
        Task.update({ where: payload.id, data })
        return await this.api().patch(`${endpoints.task[role]}/${payload.id}`, data, config)
      } catch (e) {
        Task.update({ where: payload.id, data: originalRecordData })
        throw e
      }
    } else {
      if (!payload.shipment_id) {
        throw new Error('Shipment ID is required when creating a task')
      }
      return this.api().post(`${endpoints.shipment[role]}/${payload.shipment_id}${TASK}`, data, config)
    }
  }

  static remove (taskId: string, config?: Config, role = UserRole.CLIENT) {
    return this.api().delete(`${endpoints.task[role]}/${taskId}`, { delete: taskId, ...config })
  }

  static toggleCompleted (taskId: string, config?: Config, role = UserRole.CLIENT) {
    return this.api().post(`${endpoints.task[role]}/${taskId}/toggle-completed`, {}, { persistBy: 'insert', ...config })
  }

  get due_date () {
    return this.due_date_time ? protoDateTimeToDate(this.due_date_time) : null
  }

  set due_date (date: Date | null) {
    if (!date) {
      this.due_date_time = null
    } else {
      this.due_date_time = dateToProtoDateTime(date)
    }
  }
}

export class TasksCount extends Model {
  static entity = 'tasksCounts'
  static primaryKey = 'shipment_id'

  static fields () {
    return {
      shipment_id: this.number(0),
      total: this.number(0),
      open: this.number(0),
      almost_due: this.number(0),
      overdue: this.number(0),
      shipment: this.belongsTo(Shipment, 'shipment_id')
    }
  }

  shipment_id!: number
  total!: number
  open!: number
  almost_due!: number
  overdue!: number
}
