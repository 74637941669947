import { DispatchOptions, CommitOptions } from 'vuex'
import { computed, useStore } from '@nuxtjs/composition-api'

export const useVuexPathify = <S>() => {
  const store = useStore<S>()
  const get = <T>(path: string, ...args: any[]) => computed<T>(() => store.get(path, ...args))
  const set = (path: string, data: any) => store.set(path, data)
  const sync = <T>(path: string) => {
    return computed<T>({
      get () {
        return store.get(path)
      },
      set (val) {
        return store.set(path, val)
      }
    })
  }
  const dispatch = (action: string, data?: any, options?: DispatchOptions) => store.dispatch(action, data, options)
  const commit = (mutation: string, data: any, options?: CommitOptions) => store.commit(mutation, data, options)

  return { get, set, sync, dispatch, commit }
}
