/* eslint-disable camelcase */
export default interface Location {
  formatted_address: string
  route: string
  street_number: string
  locality: string
  administrative_area_level_1: string
  postal_code: string
  country: string

  // Below items are to be removed once location is converted to embedded type on API
  id?:number,
  created_at?: string,
  updated_at?: string,
  deleted_at?: string
}

export function newLocation (data?:Partial<Location>): Location {
  return {
    formatted_address: data?.formatted_address ?? '',
    route: data?.route ?? '',
    street_number: data?.street_number ?? '',
    locality: data?.locality ?? '',
    administrative_area_level_1: data?.administrative_area_level_1 ?? '',
    postal_code: data?.postal_code ?? '',
    country: data?.country ?? ''
  }
}
