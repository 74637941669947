















import { defineComponent } from '@nuxtjs/composition-api'
import { mdiCheck } from '@mdi/js'

export default defineComponent({
  name: 'SaveButton',
  props: {
    label: {
      type: String,
      default: ''
    }
  },
  setup () {
    return {
      mdiCheck
    }
  }
})
