import createPersistedState from 'vuex-persistedstate'
import Cookies from 'js-cookie'

export default ({ store, isHMR, app }) => {
  if (isHMR) { return }

  if (!app.mixins) {
    app.mixins = []
  }

  // add persisted state as a vue mounted() mixin
  app.mixins.push({
    mounted () {
      createPersistedState({
        key: 'appState',
        getItem: key => Cookies.get(key),
        // Please see https://github.com/js-cookie/js-cookie#json, on how to handle JSON.
        setItem: (key, value) =>
          Cookies.set(key, value, { expires: 3, secure: true }),
        removeItem: key => Cookies.remove(key),
        paths: [
          'cookieWarningShown',
          'clipped',
          'miniVariant'
        ]
      })(store)
    }
  })
}
