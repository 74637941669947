

















import { computed, defineComponent, ref, PropType } from '@nuxtjs/composition-api'
import BackNextButtons from '~/components/Forms/BackNextButtons'
import ReviewsDialogPage1 from '~/components/Reviews/ReviewsDialog/ReviewsDialogPage1'
import ReviewsDialogPage2 from '~/components/Reviews/ReviewsDialog/ReviewsDialogPage2'
import Review from '~/models/review'

export default defineComponent({
  name: 'ReviewForm',
  components: {
    BackNextButtons,
    ReviewsDialogPage1,
    ReviewsDialogPage2
  },
  props: {
    review: {
      type: Object as PropType<Review>,
      default: () => new Review()
    },
    currentPage: {
      type: Number,
      required: true
    }
  },
  setup (props) {
    const form = ref()

    const pages = computed(() => {
      return [
        {
          component: ReviewsDialogPage1,
          props: {
            review: props.review
          }
        },
        {
          component: ReviewsDialogPage2,
          props: {
            review: props.review
          }
        }
      ]
    })

    return {
      form,
      pages
    }
  }
})
