export interface JWTData {
  aud: string[],
  azp: string,
  exp: number,
  'https://cargoplot.com/email': string,
  'https://cargoplot.com/email_verified': boolean,
  'https://cargoplot.com/name': string,
  iat: number,
  iss: string,
  permissions: string[],
  scope: string,
  sub: string
}

// Parses the public payload from a JWT token string
export function parseJwt (token: string): Partial<JWTData> {
  const base64Url = token.split('.')[1]
  const base64 = base64Url
    .replace(/-/g, '+')
    .replace(/_/g, '/')
  const jsonPayload = decodeURIComponent(atob(base64)
    .split('')
    .map(function (c) {
      return '%' + ('00' + c.charCodeAt(0)
        .toString(16))
        .slice(-2)
    }).join(''))
  return JSON.parse(jsonPayload)
}
