import authMiddleware from '~auth/core/middleware'
import Middleware from './middleware'
import Auth from '~auth/core/auth'
import ExpiredAuthSessionError from '~auth/inc/expired-auth-session-error'

// Active schemes
import scheme_86093c2e from '/app/config/auth.js'

Middleware.auth = authMiddleware

export default function (ctx, inject) {
  // Options
  const options = {"resetOnError":false,"scopeKey":"scope","rewriteRedirects":true,"fullPathRedirect":false,"watchLoggedIn":true,"redirect":{"login":"/auth/login","logout":"/","home":"/","callback":"/auth/authorize"},"vuex":{"namespace":"auth"},"cookie":{"prefix":"auth.","options":{"path":"/","sameSite":"lax"}},"localStorage":{"prefix":"auth."},"defaultStrategy":"auth0"}

  // Create a new Auth instance
  const $auth = new Auth(ctx, options)

  // Register strategies
  // auth0
  $auth.registerStrategy('auth0', new scheme_86093c2e($auth, {"scope":["openid","profile","email","offline_access"],"responseType":"code","grantType":"authorization_code","codeChallengeMethod":"S256","accessType":"offline","name":"auth0","endpoints":{"authorization":"https://undefined/authorize","userInfo":"https://undefined/userinfo","token":"https://undefined/oauth/token","logout":"https://undefined/v2/logout"}}))

  // Inject it to nuxt context as $auth
  inject('auth', $auth)
  ctx.$auth = $auth

  // Initialize auth
  return $auth.init().catch(error => {
    if (process.client) {
      // Don't console log expired auth session errors. This error is common, and expected to happen.
      // The error happens whenever the user does an ssr request (reload/initial navigation) with an expired refresh
      // token. We don't want to log this as an error.
      if (error instanceof ExpiredAuthSessionError) {
        return
      }

      console.error('[ERROR] [AUTH]', error)
    }
  })
}
