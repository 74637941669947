










import { defineComponent, PropType } from '@nuxtjs/composition-api'
import ReviewsScore from '~/components/Reviews/ReviewsScore'
import { useSync } from '~/composables/hooks/propsync'
import Review from '~/models/review'

export default defineComponent({
  name: 'ReviewsDialogPage1',
  components: { ReviewsScore },
  props: {
    review: {
      type: Object as PropType<Review>,
      default: () => new Review()
    }
  },
  setup (props, { emit }) {
    const propSync = useSync(props, emit)
    const localReview = propSync('review')
    return { localReview }
  }
})
