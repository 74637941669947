import { Model } from '@vuex-orm/core'
import { ADMIN } from '~/config/api'

export default class Carrier extends Model {
  // This is the name used as module name of the Vuex Store.
  static entity = 'carriers'

  static fields () {
    return {
      name: this.string(''),
      scac: this.string('')
    }
  }

  name!: string
  scac!: string

  public static fetchScacList () {
    return this.api().get(ADMIN.CARRIERS)
  }
}

interface Location {
  date: string
  port_name: string
  port_code: string
  terminal: string
}

export enum LegType {
  UNSPECIFIED,
  VESSEL,
  FEEDER_OR_BARGE,
  TRUCK_OR_RAIL
}

interface Route {
  transit_days: number
  cut_off: string
  legs: [{
    type: LegType
    vessel_name: string
    vessel_code: string
    voyage_code: string
    service: string
    departure: Location
    arrival: Location
  }]
}

export interface CarrierRoutes {
  scac: string
  name: string
  port_of_loading: string
  port_of_discharge: string
  retrieved_at: string
  routes?: Route[]
}
