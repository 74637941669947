


































































import { defineComponent, PropType, Ref, ref } from '@nuxtjs/composition-api'
import { Item } from '@vuex-orm/core'
import { mdiCheck } from '@mdi/js'
import { useI18n } from '~/composables/hooks'
import { useDeepSync } from '~/composables/hooks/propsync'
import User from '~/models/user'
import PersonalInformationPanel from '~/components/Profile/PersonalInformationCard/PersonalInformationPanel'
import CheckboxItem from '~/components/Common/CheckboxItem'

interface Validatable {
  validate: () => boolean
}

export default defineComponent({
  name: 'ProfileModal',
  components: { PersonalInformationPanel, CheckboxItem },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    user: {
      type: Object as PropType<Item<User>>,
      required: true
    },
    saving: {
      type: Boolean,
      default: false
    },
    errors: {
      type: Object,
      default: () => ({})
    }
  },
  setup (props, { emit }) {
    const deepSync = useDeepSync(props, emit)
    const localUser = deepSync('user') as Ref<User>
    const localErrors = deepSync('errors') as Ref<User>
    const { i18n } = useI18n()
    const valid = ref(true)
    const userform = ref<Validatable | null>(null)

    const validation = {
      tac: [
        (v: boolean) => !!v || i18n.t('user.form.validation.agreeToTerms')
      ]
    }

    const save = () => {
      if (userform.value !== null && userform.value.validate()) {
        emit('clicked-save', localUser.value)
      }
    }

    return { localUser, validation, localErrors, mdiCheck, save, userform, valid }
  }
})
