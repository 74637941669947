























































import { defineComponent, PropType, ref, watch } from '@nuxtjs/composition-api'
import { mdiClose } from '@mdi/js'
import SmoothReflow from '~/components/SmoothReflow'
import ReviewInteractor from '~/components/Containers/ReviewInteractor'
import ReviewItem from '~/components/Reviews/ReviewNotificationsDialog/ReviewItem'
import Review from '~/models/review'
import { UserRole } from '~/composables/enums'
import { useDeepWatch } from '~/composables/hooks/propsync'

export default defineComponent({
  name: 'NotificationsDialog',
  components: {
    SmoothReflow,
    ReviewInteractor,
    ReviewItem
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    reviews: {
      type: Array as PropType<Review[]>,
      default: () => []
    },
    role: {
      type: Number,
      default: UserRole.CLIENT
    }
  },
  setup (props, { emit }) {
    const propWatch = useDeepWatch(props)
    const processed = ref(false)
    const localReviews = propWatch('reviews')

    watch(
      () => localReviews.value.length,
      (val) => {
        if (val === 0) {
          close()
        }
      }
    )

    const removeItem = (id: number) => {
      localReviews.value = localReviews.value.filter((review) => {
        return review.id !== id
      })
    }

    const close = () => {
      emit('input', false)
    }

    return { mdiClose, processed, localReviews, removeItem, close }
  }
})
