import { Collector, DocumentType, Incoterms, QuoteStatus, UserRole } from '../enums'
import { useRole } from '../hooks'
import Quote from '~/models/quote'
import DocumentSlot from '~/models/documentSlot'
import Invoice from '~/models/invoice'
import Review from '~/models/review'

interface Gtm {
  push(event: {
    [key: string]: any;
    event: string;
  }): void
}

export enum Event {
  QUOTE_VIEW = 'quote_view',
  QUOTE_PUBLISHED = 'quote_published',
  QUOTE_ACCEPTED = 'quote_accepted',
  INVOICE_PAID = 'invoice_paid',
  DOCUMENT_UPLOADED = 'document_uploaded',
  SUPPLIER_INFO_PROVIDED = 'supplier_info_provided',
  ADDITIONAL_SERVICES_PROVIDED = 'additional_services_provided',
  REVIEW_SUBMISSION = 'review_submission',
  REVIEW_UPDATED = 'review_updated',
  REVIEW_DELETED = 'review_deleted',
  REVIEW_RETRACTED = 'review_retracted',
}

export function wrapGtm (gtm: Gtm, userProvided: boolean) {
  const user_role = userProvided
    ? UserRole[useRole()]
    : UserRole[UserRole.CLIENT]
  return {
    push: gtm.push,
    pushDocumentUploaded (doc: DocumentSlot, reference: string) {
      gtm.push({
        event: Event.DOCUMENT_UPLOADED,
        document_type: DocumentType[doc.type],
        cargoplot_reference: reference,
        user_role
      })
    },
    pushSupplierInfoProvided (reference: string) {
      gtm.push({
        event: Event.SUPPLIER_INFO_PROVIDED,
        cargoplot_reference: reference,
        user_role
      })
    },
    pushAdditionalServicesProvided (reference: string, fields: string[]) {
      gtm.push({
        event: Event.ADDITIONAL_SERVICES_PROVIDED,
        cargoplot_reference: reference,
        fields,
        user_role
      })
    },
    pushQuoteView (quote: Quote) {
      gtm.push({
        event: Event.QUOTE_VIEW,
        quote_status: QuoteStatus[quote.status],
        user_role
      })
    },
    pushQuotePublished (quote: Quote, reference: string, client_id: number) {
      gtm.push({
        event: Event.QUOTE_PUBLISHED,
        quote_reference: quote.reference,
        revenue: quote.agent_fee_euro_cents / 100,
        gmv: quote.total_price_euro_cents_ex / 100,
        incoterm: Incoterms[quote.incoterm],
        cargo_type: quote.cargo_type,
        transport_mode: quote.transport_mode,
        total_weight_kg: quote.total_weight,
        total_volume_m3: quote.total_volume,
        cargoplot_reference: reference,
        agent: quote.forwarder?.name ?? 'Unknown',
        client_id
      })
    },
    pushQuoteAccepted (quote: Quote, reference: string) {
      gtm.push({
        event: Event.QUOTE_ACCEPTED,
        quote_reference: quote.reference,
        revenue: quote.agent_fee_euro_cents / 100,
        gmv: quote.total_price_euro_cents_ex / 100,
        incoterm: Incoterms[quote.incoterm],
        cargo_type: quote.cargo_type,
        transport_mode: quote.transport_mode,
        total_weight_kg: quote.total_weight,
        total_volume_m3: quote.total_volume,
        cargoplot_reference: reference,
        agent: quote.forwarder?.name ?? 'Unknown',
        line_items: quote.line_items.map(item => ({
          item_name: item.description
        })),
        user_role
      })
    },
    pushInvoicePayment (invoice: Invoice) {
      gtm.push({
        event: Event.INVOICE_PAID,
        revenue: invoice.agent_fee_cents / 100,
        gmv: invoice.total_price_cents_ex / 100,
        cargoplot_reference: invoice.shipment_snapshot.cargoplot_id,
        collector: Collector[invoice.collector]
      })
    },
    pushReviewSubmission (review: Review, reference: string) {
      gtm.push(reviewGtmData(Event.REVIEW_SUBMISSION, review, reference, user_role))
    },
    pushReviewUpdated (review: Review, reference: string) {
      gtm.push(reviewGtmData(Event.REVIEW_UPDATED, review, reference, user_role))
    },
    pushReviewDeleted (review: Review, reference: string) {
      gtm.push(reviewGtmData(Event.REVIEW_DELETED, review, reference, user_role))
    },
    pushReviewRetracted (review: Review, reference: string) {
      gtm.push(reviewGtmData(Event.REVIEW_RETRACTED, review, reference, user_role))
    }
  }
}

function reviewGtmData (event: Event, review: Review, reference: string, userRole: string) {
  if (event === Event.REVIEW_SUBMISSION || event === Event.REVIEW_UPDATED) {
    return {
      event,
      cargoplot_reference: reference,
      score_overall: review.score_overall,
      score_customs: review.score_customs,
      score_communication: review.score_communication,
      score_delivery: review.score_delivery,
      agent: review.forwarder?.name ?? 'Unknown',
      user_role: userRole
    }
  } else if (event === Event.REVIEW_RETRACTED || event === Event.REVIEW_DELETED) {
    return {
      event,
      cargoplot_reference: reference,
      agent: review.forwarder?.name ?? 'Unknown',
      user_role: userRole
    }
  }
  return { event }
}
