/* eslint-disable camelcase */
import { Config, Response } from '@vuex-orm/plugin-axios'
import { cloneDeep, pick, omit } from 'lodash-es'
import CargoplotModel from './cargoplot'
import User from './user'
import Organization from './organization'
import CargoComponent from './cargoComponent'
import DocumentSlot from './documentSlot'
import Quote from './quote'
import Invoice from './invoice'
import Review from './review'
import ShipmentStage, { ShipmentStages } from './shipmentStage'
import { ReadStatus } from './message'
import { TasksCount } from './task'
import { ADMIN, SHIPMENT, FORWARDER, DOCUMENT } from '~/config/api'
import {
  Currency, UserRole, CargoTypes, Incoterms, Contents, TransportModes, QuoteStatus, InvoiceStatus,
  ProtoContainerType
} from '~/composables/enums'
import Location from '~/models/location'
import { Modify, ProtoDate, ProtoDateTime, ProtoPostalAddress } from '~/composables/types'

export interface ShippedContainer {
  key?: string
  rank?: number
  type: ProtoContainerType
  container_number?: string
  discharge_date?: ProtoDate|null
  delivery_time?: ProtoDateTime|null
  delivery_window_hours?: number
  destination?: ProtoPostalAddress
  notes?: string
}

const endpoints: Record<UserRole, string> = {
  [UserRole.CLIENT]: SHIPMENT,
  [UserRole.ADMIN]: ADMIN.SHIPMENT,
  [UserRole.FORWARDER]: FORWARDER.SHIPMENT
}

export default class Shipment extends CargoplotModel {
  // This is the name used as module name of the Vuex Store.
  static entity = 'shipments'

  static state () {
    return {
      clientFetchTime: '',
      forwarderFetchTime: '',
      bannerVisibility: {},
      overviewTableColumns: null
    }
  }

  static fields () {
    return {
      id: this.number(0),
      created_at: this.attr(''),
      updated_at: this.attr(''),
      deleted_at: this.attr(null),
      user_id: this.number(0),
      inquiry_id: this.number(0),
      cargoplot_id: this.attr(''),
      hubspot_deal_id: this.attr(''),
      forwarder_reference: this.attr(''),
      description: this.attr(''),
      transport_date: this.attr(''),
      incoterm: this.number(0),
      total_weight: this.number(0),
      total_volume: this.number(0),
      cargo_type: this.string(''),
      transport_mode: this.attr([]),
      special_contents: this.attr([]),
      status: this.number(0),
      eta: this.attr(''),
      etd: this.attr(''),
      deconsolidation: this.attr(''),
      delivery_from: this.attr(''),
      delivery_to: this.attr(''),
      vessel: this.attr(''),
      vessel_longitude: this.attr(0),
      vessel_latitude: this.attr(0),
      co2_emission: this.attr(''),
      current_location: this.attr(''),
      container_number: this.attr(''),
      mbl_number: this.attr(''),
      tracking_code: this.attr(''),
      user: this.belongsTo(User, 'user_id'),
      origin: this.attr({}),
      destination: this.attr({}),
      cargo_components: this.attr([]),
      containers: this.attr([]),
      port_of_loading: this.attr(''),
      port_of_discharge: this.attr(''),
      notes: this.attr(''),
      private_notes: this.attr(''),
      document_slots: this.hasMany(DocumentSlot, 'shipment_id'),
      hs_codes: this.attr(''),
      pallet_exchange: this.boolean(false),
      tailgate: this.boolean(false),
      delivery_address_confirmed: this.boolean(undefined),
      quote_id: this.number(0),
      quote: this.belongsTo(Quote, 'quote_id'),
      price_updates: this.hasMany(Quote, 'shipment_id'),
      shipping_line: this.attr(''),
      payment_term: this.boolean(false),
      insured_goods_value: this.number(0),
      insured_goods_currency: this.attr(null),
      supplier_email: this.attr(''),
      supplier_phone: this.attr(''),
      supplier_address: this.attr(''),
      invoices: this.hasMany(Invoice, 'shipment_id'),
      review_id: this.number(0),
      reviews: this.hasMany(Review, 'shipment_id'),
      live_map_url: this.string(''),
      shipment_stages: this.attr([]),
      flags: this.attr([]),
      current_price_valid_till: this.attr([]),
      drafts: this.number(0),
      overdue_invoices: this.number(0),
      total_agent_fee: this.number(0),
      assignee_id: this.number(null),
      assignee: this.belongsTo(User, 'assignee_id'),
      read_status: this.hasOne(ReadStatus, 'cargoplot_reference', 'cargoplot_id'),
      notes_count: this.number(0),
      tasks_count: this.hasOne(TasksCount, 'shipment_id'),
      coordinator_id: this.number(null),
      coordinator: this.belongsTo(Organization, 'coordinator_id')
    }
  }

  id!: number
  created_at!: string
  updated_at!: string
  deleted_at?: string
  user_id!: number
  inquiry_id!: number
  cargoplot_id!: string
  hubspot_deal_id!: string
  forwarder_reference!: string
  description!: string
  transport_date!: string
  incoterm!: Incoterms
  total_weight!: number
  total_volume!: number
  cargo_type!: CargoTypes
  transport_mode?: TransportModes | null
  special_contents!: Contents[]
  status!: number
  eta?: string
  etd?: string
  deconsolidation?: string
  delivery_from?: string
  delivery_to?: string
  vessel!: string
  vessel_latitude!: number
  vessel_longitude!: number
  current_location!: string
  container_number!: string
  mbl_number!: string
  tracking_code!: string
  supplier_contact_details!: string
  user?: User
  origin!: Location
  destination!: Location
  cargo_components?: CargoComponent[]
  containers?: ShippedContainer[]
  port_of_loading!: string
  port_of_discharge!: string
  notes!: string
  private_notes!: string
  document_slots!: DocumentSlot[]
  hs_codes?: string
  pallet_exchange!: boolean
  tailgate!: boolean
  delivery_address_confirmed!: boolean
  quote_id?: number
  quote?: Quote
  price_updates?: Quote[]
  shipping_line!: string
  payment_term!: boolean
  insured_goods_value!: number
  insured_goods_currency?: Currency | null
  supplier_email?: string
  supplier_phone?: string
  supplier_address?: string
  invoices?: Invoice[]
  review_id?: number
  reviews?: Review[]
  live_map_url!: string
  shipment_stages!: ShipmentStage[]|null
  co2_emission!: string
  flags!: string[]
  current_price_valid_till?: string
  drafts?: number
  overdue_invoices?: number
  total_agent_fee?: number
  assignee_id?: number|null
  assignee?: User|null
  read_status?: ReadStatus
  notes_count!: number
  tasks_count?: TasksCount
  coordinator_id?: number
  coordinator?: Organization|null

  public static get (id: string|number, role = UserRole.CLIENT) {
    const q = Shipment.query()
      .where((shp: Shipment) => shp.cargoplot_id === id || shp.id === Number.parseInt(id.toString()))
      // .where('cargo_type', (value: string) => value !== '') // Find out if this line is still necessary, some day
      .with('user.organization')
      .with('document_slots.document')

    if ([UserRole.ADMIN, UserRole.FORWARDER].includes(role)) {
      q.with('assignee|coordinator')
    }

    if (role !== UserRole.FORWARDER) {
      q
        .with('quote')
        .with('reviews', (query) => {
          if (role === UserRole.CLIENT) {
            query.where('user_role', role)
          }
        })
        .with('price_updates', (query) => {
          if (role === UserRole.CLIENT) {
            query.where((pu: Quote) => pu.status === QuoteStatus.STATUS_PUBLISHED)
          }
          query
            .orderBy('published_at', 'desc')
            .orderBy('updated_at', 'desc')
        })
        .with('invoices', (query) => {
          if (role === UserRole.CLIENT) {
            query.where((i: Invoice) => [
              InvoiceStatus.STATUS_PUBLISHED, InvoiceStatus.STATUS_PAID
            ].includes(i.status))
          }
          query
            .with('organization')
            .with('payments')
            .orderBy('status', 'asc')
            .orderBy('published_at', 'desc')
            .orderBy('updated_at', 'desc')
        })
    }
    return q.first()
  }

  public static findByIDs (ids: Shipment['id'][], role = UserRole.CLIENT, forwarderId?: Organization['id']) {
    const q = Shipment.query()
      .whereIdIn(ids)
      .where('cargo_type', (value: string) => value !== '')
      .with('user.organization')
      .with('read_status')

    if ([UserRole.ADMIN, UserRole.FORWARDER].includes(role)) {
      q.with('tasks_count')
        .with('assignee|coordinator')
    }
    if (role === UserRole.FORWARDER) {
      if (!forwarderId) {
        throw new Error('Forwarder ID is required')
      }
      q.where((shipment: Shipment) =>
        !!shipment.shipment_stages?.some(stage => stage.forwarder_id === forwarderId) ||
        shipment.coordinator_id === forwarderId
      )
    }
    return q.get()
  }

  /**
   * Fetch all shipments
   *
   * @static
   * @param {Config} config
   * @returns {Promise<Response>}
   * @memberof Shipment
   */
  public static fetchAll (config?: Config, role = UserRole.CLIENT) {
    return this.fetch(endpoints[role], config)
  }

  /**
   * Fetch shipments for document inspection. Mainly shipments for which the requesting organization
   * is attached on the import formalities stage
   *
   * @static
   * @param {Config} [config]
   * @return {*}
   * @memberof Shipment
   */
  public static fetchAllForDocInspection (config?: Config) {
    const role = UserRole.FORWARDER
    return this.fetch(
      endpoints[role],
      {
        params: { stage: 'SHIPMENT_STAGE_IMPORT_FORMALITIES' },
        ...config
      },
      { field: `${UserRole[role].toLowerCase()}DocumentsFetchTime`, usage: 'saveAndSend' }
    )
  }

  /**
   * Fetch all shipments for the user organization
   *
   * @static
   * @param {Config} config
   * @returns {Promise<Response>}
   * @memberof Shipment
   */
  public static fetchAllForOrganization (organizationId?: number, config?: Config, role = UserRole.CLIENT) {
    const endpoint = role === UserRole.ADMIN
      ? `/admin/organization-shipment/${organizationId}`
      : `/organization-shipment/${organizationId}`
    return this.api().get(endpoint, config)
  }

  /**
   * Fetch a single shipment by ID
   * @param id The ID of the shipment
   * @param config The axios or vuex-orm config parameters
   * @param role
   * @returns A response object containing the data
   */
  public static fetchOne (id: string|number, config?: Config, role = UserRole.CLIENT) {
    return this.api().get(`${endpoints[role]}/${id}`, config)
  }

  public static fetchArrivals (config?: Config) {
    return this.api().get(ADMIN.ARRIVALS, { ...config, save: false })
  }

  public static fetchDepartures (config?: Config) {
    return this.api().get(ADMIN.DEPARTURES, { ...config, save: false })
  }

  public static async fetchAvailableOverviewTableColumns (config?: Config) {
    const result = await this.api().get(`${ADMIN.SHIPMENT}/overview/fields`, { ...config, save: false })
    this.commit((state) => {
      state.overviewTableColumns = result.getDataFromResponse()
    })
  }

  public static fetchOverviewTable (config?: Config) {
    return this.api().get(`${SHIPMENT}/overview`, { ...config, save: false })
  }

  /**
   * Update the current shipment instance
   *
   * @param {Partial<Shipment>} data
   * @param {Config} [config]
   * @param {UserRole} role
   * @return {*}  {Promise<Response>}
   * @memberof Shipment
   */
  public async persist (data: Partial<Shipment>, config?: Config, role = UserRole.CLIENT) {
    const originalRecord = this.$toJson()
    const sanitizedData = sanitize(data, role)
    try {
      // Already merge the changes in the local data set as if the remote operation succeeded
      // (i.e. optimistic UI)
      this.$update(sanitizedData)
      return await Shipment.api().put(`${endpoints[role]}/${this.id}`, sanitizedData, config)
    } catch (e) {
      // On error, restore the original record. Sadly doesn't work for related data such as
      // cargo_components or containers.
      this.$update(originalRecord)
      throw e
    }
  }

  public fetchSendLog () {
    return Shipment.api().get(`${endpoints[UserRole.ADMIN]}/${this.cargoplot_id}/sendlog`, { save: false })
  }

  /**
   * Download all documents of a shipment
   * @param id [number] The ID of the shipment
   * @param {Config} [config] The axios or vuex-orm config parameters
   * @param {UserRole} role
   * @returns A response object containing the data
   */
  public static downloadAllDocuments (id?:string|number, config?: Config, role = UserRole.CLIENT) {
    return this.api().get(`${endpoints[role]}/${id}${DOCUMENT}`, { ...config, save: false })
  }

  /**
   * Request documents for the forwarder
   * @param id [number] The ID of the shipment
   * @param {Config} [config] The axios or vuex-orm config parameters
   * @returns A response object containing the data
   */
  public static requestDocuments (id?:string|number, config?: Config): Promise<Response> {
    return this.api().post(`${ADMIN.SHIPMENT}/${id}/document-request`, {}, {
      ...config,
      save: false
    })
  }

  get import_customs_agent () {
    if (this.shipment_stages && this.shipment_stages.length > 0) {
      const stage = this.shipment_stages.find(
        item => item.stage === ShipmentStages.SHIPMENT_STAGE_IMPORT_FORMALITIES
      )
      if (stage) {
        if (stage.forwarder) {
          return stage.forwarder
        }
        if (stage.forwarder_id) {
          return Organization.find(stage.forwarder_id)
        }
      }
    }
  }

  get export_customs_agent () {
    if (this.shipment_stages && this.shipment_stages.length > 0) {
      const stage = this.shipment_stages.find(
        item => item.stage === ShipmentStages.SHIPMENT_STAGE_EXPORT_FORMALITIES
      )
      if (stage) {
        if (stage.forwarder) {
          return stage.forwarder
        }
        if (stage.forwarder_id) {
          return Organization.find(stage.forwarder_id)
        }
      }
    }
  }

  get forwarder () {
    if (this.shipment_stages && this.shipment_stages.length > 0) {
      const stage = this.shipment_stages.find(
        item => item.stage === ShipmentStages.SHIPMENT_STAGE_CARRIAGE_TRANSIT
      )
      if (stage) {
        if (stage.forwarder) {
          return stage.forwarder
        }
        if (stage.forwarder_id) {
          return Organization.find(stage.forwarder_id)
        }
      }
    }
  }
}

export type Supplier = Pick<Shipment, 'supplier_email'|'supplier_phone'|'supplier_address'>

function sanitize (data: Partial<Shipment>, role = UserRole.CLIENT) {
  interface ShipmentPayload extends Modify<Partial<Shipment>, {
    shipment_stages: Partial<ShipmentStage>[] | null
    document_slots: Partial<DocumentSlot>[]
  }>{}

  const sanitized: Partial<ShipmentPayload> = cloneDeep(data)
  delete sanitized.id
  delete sanitized.read_status
  if (sanitized.origin) {
    sanitized.origin = omit(sanitized.origin, 'id')
  }
  if (sanitized.destination) {
    sanitized.destination = omit(sanitized.destination, 'id')
  }
  if (sanitized.document_slots && sanitized.document_slots.length > 0) {
    sanitized.document_slots = sanitized.document_slots
      .map(item => pick(item,
        ['id', 'label', 'type', 'status', 'due_date', 'rejection_reason', 'document_id'])
      )
  }
  if (sanitized.insured_goods_value) {
    sanitized.insured_goods_value = (Number(sanitized.insured_goods_value.toFixed(0))) || 0
  }

  if (sanitized.shipment_stages) {
    sanitized.shipment_stages = sanitized.shipment_stages
      .map(({ forwarder_id, stage }) => ({ forwarder_id, stage }))
      .filter(({ forwarder_id }) => !!forwarder_id)
  }
  if (role !== UserRole.ADMIN) {
    delete sanitized.cargo_type
  }
  return sanitized
}
