










import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  setup () {
    const options = [
      '0-15', '15-50', '50-100', '100-200', '200-500', '500-1000', '1000+'
    ]
    return { options }
  }
})
