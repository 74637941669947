import { Ref, ref, computed, watch } from '@vue/composition-api'
import { isEqual, cloneDeep, debounce } from 'lodash-es'

type EmitFunc = (event: string, ...args: any[]) => void

/**
 * Create a get/set object for a prop to use as a computed property
 */
export function useSync<P extends {}> (props: P, emit: EmitFunc) {
  return <K extends keyof P> (field: K) => computed({
    get () {
      return props[field]
    },
    set (val: P[K]): void {
      if (props[field] === val) { return } // Don't emit the same value again
      emit(`update:${String(field)}`, val)
    }
  })
}

/**
 * Create a ref that automatically syncs with the supplied prop. Uses update events to propagate changes up
 * to the parent.
 */
export function useDeepSync<Props extends {}> (props: Props, emit: EmitFunc) {
  return <Field extends keyof Props> (
    field: Field,
    options?: {
      transformer?: (arg: Props[Field]) => Props[Field],
      debounceMs?: number
    }
  ) => {
    let copy = cloneDeep(props[field])
    if (typeof options?.transformer === 'function') {
      copy = options.transformer(copy)
    }
    const localVar = ref(copy) as Ref<Props[Field]>
    watch(
      () => props[field],
      (vals) => {
        if (!isEqual(vals, localVar.value)) {
          let copy = cloneDeep(vals)
          if (typeof options?.transformer === 'function') {
            copy = options.transformer(copy)
          }
          localVar.value = copy
        }
      },
      { deep: true, immediate: true }
    )

    let updateFunc = (vals: Props[Field]) => {
      if (!isEqual(vals, props[field])) {
        emit(`update:${String(field)}`, cloneDeep(vals))
      }
    }
    if (options?.debounceMs && options.debounceMs > 0) {
      updateFunc = debounce(updateFunc, options.debounceMs)
    }

    watch(
      localVar,
      updateFunc,
      { deep: true }
    )
    return localVar
  }
}

/**
 * Create a ref that automatically updates if the supplied prop changes.
 */
export function useDeepWatch<Props extends {}> (props: Props) {
  return <Field extends keyof Props> (field: Field) => {
    const localVar = ref(cloneDeep(props[field])) as Ref<Props[Field]>
    watch(
      () => props[field],
      (vals) => {
        if (!isEqual(vals, localVar.value)) {
          localVar.value = cloneDeep(vals)
        }
      }, { deep: true, immediate: true }
    )
    return localVar
  }
}
