import ReviewInteractor from './ReviewInteractor.vue'
export default ReviewInteractor

export interface StatusFields {
  retract?: boolean,
  save?: boolean,
  delete?: boolean,
  postpone?: boolean,
  refuse?: boolean
}
