


















import { defineComponent } from '@nuxtjs/composition-api'
import { mapState, mapActions } from 'vuex'

export default defineComponent({
  computed: {
    ...mapState('notifications', ['current'])
  },
  methods: {
    ...mapActions('notifications', ['pop'])
  }
})
