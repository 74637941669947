import axios, { AxiosError, AxiosResponse } from 'axios'

type Notify = undefined | ((arg0: {message: string, color: string}) => void)
export type AtLeast<T, K extends keyof T> = Partial<T> & Pick<T, K>

/**
 * Process errors returned by axios
 *
 * @param {AxiosError|Error} e An object describing the error. Can be one returned by axios or a common
 * Javascript error object
 * @param {Notify|null} notify Optional notification function to call upon errors. The function should
 * expect and object with a message field (str) describing ther error message and a color field (str)
 * indicating the color of the notification to be shown
 * @param {boolean} print Flag that indicates if error should be output to console.
 * @returns {Object} the fields that errorred with their corresponding validation message
 */
export function processErrors (e: AxiosError|Error|unknown, notify?: Notify|null, print = false): {} {
  const errors: any = {}
  let message: string = ''
  if (axios.isAxiosError(e) && typeof e.response !== 'undefined') {
    if (Array.isArray(e.response.data)) {
      const validationErrors = (e.response as AxiosResponse<any[]>).data

      for (const err of validationErrors) {
        errors[err.field] = err.message
      }
      message = 'There were some problems with your input. Please review the form.'
    } else {
      const response: AxiosResponse<any> = e.response
      message = response?.data?.error?.message || response?.data?.message || response?.data || e || ''
    }
  } else if (e instanceof Error) {
    message = e.toString()
  }

  if (typeof notify !== 'undefined' && notify !== null && message !== '') {
    notify({
      message,
      color: 'error'
    })
  }
  if (print) {
    // eslint-disable-next-line no-console
    console.error(e)
  }
  return errors
}
