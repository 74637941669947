
















import { defineComponent, PropType } from '@nuxtjs/composition-api'
import { NavItem } from '~/composables/types'

export default defineComponent({
  props: {
    item: {
      type: Object as PropType<NavItem>,
      required: true
    }
  }
})
