export enum UserRole {
  ADMIN = 1,
  CLIENT = 3,
  FORWARDER = 4
}

export enum CargoTypes {
  UNSPECIFIED = 'unspecified',
  PACKAGES = 'packages',
  PALLETS = 'pallets',
  CONTAINERS = 'containers'
}

export enum ContainerTypes {
  UNSPECIFIED = 'unspecified',
  STD_DRY_20 = '20\' standard dry container',
  STD_DRY_40 = '40\' standard dry container',
  HC_40 = '40\' high cube container',
  HC_45 = '45\' high cube container',
  REEFER_20 = '20\' reefer container',
  REEFER_40 = '40\' reefer container',
  OPEN_TOP_20 = '20\' open top container',
  OPEN_TOP_40 = '40\' open top container',
  FLAT_RACK_20 = '20\' flat rack container',
  FLAT_RACK_40 = '40\' flat rack container',
  OPEN_TOP_HIGH_CUBE_40 = '40\' open top high cube container',
}

export enum ProtoContainerType {
  CONTAINER_TYPE_UNSPECIFIED = 0,
  CONTAINER_TYPE_20_STANDARD_DRY = 1,
  CONTAINER_TYPE_40_STANDARD_DRY = 2,
  CONTAINER_TYPE_40_HIGH_CUBE = 3,
  CONTAINER_TYPE_45_HIGH_CUBE = 4,
  CONTAINER_TYPE_20_REEFER = 5,
  CONTAINER_TYPE_40_REEFER = 6,
  CONTAINER_TYPE_20_OPEN_TOP = 7,
  CONTAINER_TYPE_40_OPEN_TOP = 8,
  CONTAINER_TYPE_20_FLAT_RACK = 9,
  CONTAINER_TYPE_40_FLAT_RACK = 10,
  CONTAINER_TYPE_40_OPEN_TOP_HIGH_CUBE = 11
}

export enum Contents {
  BATT = 'batteries',
  TEX = 'textiles',
  LIQ = 'liquids'
}

export enum TransportModes {
  SEA = 'sea',
  AIR = 'air',
  RAIL = 'rail',
  ROAD = 'road'
}

export enum LoadingUserState {
  LOADING = 0,
  FAILED,
  SUCCESS
}

export enum ClientType {
  BUSINESS = 'business',
  PRIVATE = 'private'
}

export enum Incoterms {
  INCOTERM_UNSPECIFIED,
  INCOTERM_EXW,
  INCOTERM_FCA,
  INCOTERM_CPT,
  INCOTERM_CIP,
  INCOTERM_DPU,
  INCOTERM_DAP,
  INCOTERM_DDP,
  INCOTERM_FAS,
  INCOTERM_FOB,
  INCOTERM_CFR,
  INCOTERM_CIF,
}

export enum InquiryStatus {
  UNSPECIFIED,
  RECEIVED,
  PRICES_REQUESTED,
  QUOTES_PROPOSED,
  QUOTE_ACCEPTED,
  CANCELLED,
  DELETED_BY_CLIENT,
}

export enum ShipmentStatus {
  UNSPECIFIED,
  ACTIVE,
  COMPLETED,
  CANCELLED,
  ON_HOLD,
}

export enum QuoteStatus {
  STATUS_UNSPECIFIED,
  STATUS_DRAFT,
  STATUS_PUBLISHED,
  STATUS_ACCEPTED,
  STATUS_REJECTED
}

export enum IncotermComponents {
  PICKUP = 0,
  TRANSPORT_SUPPLIER_TO_TERMINAL,
  CUSTOMS_ORIGIN,
  THC_ORIGIN,
  TRANSPORT_ORIGIN_TO_DESTINATION,
  THC_DESTINATION,
  CUSTOMS_DESTINATION,
  TRANSPORT_TERMINAL_TO_DESTINATION,
  INSURANCE
}

export enum OrganizationType {
  UNSPECIFIED,
  CLIENT,
  FORWARDER,
  SUPPLIER
}

export enum Currency {
  EUR = 'EUR',
  USD = 'USD',
  GBP = 'GBP',
  SEK = 'SEK',
  AUD = 'AUD',
  CAD = 'CAD',
}

export enum DocumentSlotStatus {
  UNSPECIFIED,
  TO_BE_RECEIVED,
  RECEIVED,
  REJECTED = 4,
  DELETED = 5
}

export enum DocumentType {
  UNSPECIFIED,
  COMMERCIAL_INVOICE,
  PACKING_LIST,
  BILL_OF_LADING,
  COC_EXCERPT,
  DIRECT_REPRESENTATION,
  OTHER,
  UTB,
  TTW,
  HOUSE_BILL_OF_LADING,
  HOUSE_AIRWAY_BILL,
  FISCAL_REPRESENTATION,
  INSURANCE_CERTIFICATE,
  DELIVERY_ORDER
}

export enum MediaType {
  UNSPECIFIED,
  PDF,
  DOCX,
  XLSX,
  TXT,
  PNG,
  JPG,
  GIF
}

export enum Source {
  UNSPECIFIED,
  WEBSITE,
  DASHBOARD,
  QUOTE_ENGINE,
  PDF_TEMPLATE_ENGINE,
}

export enum InvoiceStatus {
  STATUS_UNSPECIFIED,
  STATUS_DRAFT,
  STATUS_PUBLISHED,
  STATUS_PAID
}

export enum Collector {
  COLLECTOR_UNSPECIFIED,
  COLLECTOR_CARGOPLOT,
  COLLECTOR_SPRINQUE
}

/**
 * Converts a TypeScript enum object to a version that can be provided to a select input element.
 * @param enumValue The enum to convert
 * @returns The object containing the selection options as {number => string} object
 */
export function enumToSelectOptions (enumValue: Record<string | number, string | number>) {
  return Object.entries(enumValue)
    .reduce((previous, [key, value]) => {
      if (Number.isInteger(parseInt(key)) && typeof value === 'string') {
        previous[key] = value
      }
      return previous
    }, {} as Record<string, string>)
}

export function protoContainerTypeToString (containerType: ProtoContainerType, short = false) {
  let base = ProtoContainerType[containerType]
  if (!base) {
    return ''
  }
  base = base
    .replace('CONTAINER_TYPE_', '')
    .replaceAll('_', ' ')

  if (short) {
    base = base.replace('STANDARD DRY', 'DC')
      .replace('HIGH CUBE', 'HC')
      .replace('REEFER', 'RF')
      .replace('OPEN TOP', 'OT')
      .replace('FLAT RACK', 'FR')
      .replace('OPEN TOP HIGH CUBE', 'OT HC')
    return base
  }
  return base.toLowerCase()
}
