



















import { defineComponent } from '@nuxtjs/composition-api'
import { useDeepSync } from '~/composables/hooks/propsync'

export default defineComponent({
  name: 'CheckboxItem',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ''
    },
    downloadTac: {
      type: Boolean,
      default: false
    },
    validation: {
      type: Array,
      default: () => ([])
    }
  },
  setup (props, { emit }) {
    const deepSync = useDeepSync(props, emit)
    const localValue = deepSync('value')
    return { localValue }
  }
})
