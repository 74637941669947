























import { defineComponent } from '@nuxtjs/composition-api'
import UserMenu from './UserMenu'
import { useSync } from '~/composables/hooks/propsync'

export default defineComponent({
  name: 'AppBar',
  components: { UserMenu },
  props: {
    drawer: {
      type: Boolean,
      required: true
    }
  },
  setup (props, { emit }) {
    const sync = useSync(props, emit)
    const localDrawer = sync('drawer')
    return { localDrawer }
  }
})
